import { useEffect, useState } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import LeagueModel from "../Models/LeagueModel";
import { useUserContext } from "../Utility/useUserContext";
import TeamModel from "../Models/TeamModel";
import { Wallet } from "../Components/Wallet";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TLError } from "../Utility/TLError";

export const Leagues = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [totalLeagueValueHovered, setTotalLeagueValueHovered] = useState(false);
    const [leagues, setLeagues] = useState<LeagueModel[]>([]);

    const [randomLeagueButtonTriggered, setRandomLeagueButtonTriggered] = useState(false);
    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    useEffect(() => {
        document.title = t('title.leagues');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
        };

    }, [userAuth.token, userContext.currentTeamId]);

    useEffect(() => {

        setIsLoading(true);
        const getLeagues = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getLeagues.action,
                ENDPOINTS.getLeagues.method,
                userAuth.token
            );

            setLeagues(responseJson.leagues);

            setIsLoading(false);
            setError(new TLError("", ""));
        };
        getLeagues().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, randomLeagueButtonTriggered]);

    async function addRandomLeague() {

        setIsLoading(true);
        try {

            await ApiCall(
                ENDPOINTS.addRandomLeague.action,
                ENDPOINTS.addRandomLeague.method,
                userAuth.token,
                { name: "random league" }
            );
            setRandomLeagueButtonTriggered(!randomLeagueButtonTriggered);

            setIsLoading(false);
            setError(new TLError("", ""));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    }

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            <h5>{t('leagues.title')}</h5>
            <Row lg={3}>
                {leagues.map((league) => (
                    <div key={league.id}>
                        <Col className="d-flex">
                            <Card className="flex-fill mt-3 shadow p-3 mb-3 bg-body rounded">
                                <Row className="g-0">
                                    <Col md={2}>
                                        <div className="d-none d-lg-block">
                                            <img
                                                src={league.id % 2 === 0 ? require("./../Assets/images/football1.jpg") : require("./../Assets/images/football2.jpg")}
                                                width="60"
                                                height="100"
                                                alt="Default"
                                            />
                                        </div>
                                        <div className="d-lg-none d-flex justify-content-center align-items-center">
                                            <img
                                                src={league.id % 2 === 0 ? require("./../Assets/images/football1.jpg") : require("./../Assets/images/football2.jpg")}
                                                width="60"
                                                height="100"
                                                alt="Default"
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Card.Body>
                                            <Card.Title> {t('leagues.totalTeams')} {league.teams.length} </Card.Title>
                                            <h4>{league.name}</h4>
                                            <hr />
                                            <Card.Text onMouseEnter={() => setTotalLeagueValueHovered(true)} onMouseLeave={() => setTotalLeagueValueHovered(false)}>
                                                {" "}
                                                {t('leagueTable.totalLeagueValue')} {totalLeagueValueHovered ? league.leagueValue.toLocaleString(undefined, { minimumFractionDigits: 2 }) : league.formattedLeagueValue}
                                            </Card.Text>
                                            <Card.Text>
                                                {" "}
                                                {t('leagues.leagueCreatedAt')}
                                                <br />
                                                {moment(league.createDate).format("HH:mm:SS DD/MM/YYYY")}
                                            </Card.Text>
                                            <Card.Text style={{ color: "red" }}>
                                                {league.status === "P" &&
                                                    t('leagues.leagueIsOver')
                                                }</Card.Text>
                                            <Card.Text style={{ color: "green" }}>
                                                {league.status === "A" &&
                                                    t('leagues.leagueIsInProgress')
                                                }
                                            </Card.Text>
                                            <Link className="btn btn-primary" to={"leagues/" + league.id}>
                                                {t('leagues.checkTheTeams')}
                                            </Link>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <hr />
                    </div>
                ))}
            </Row>
            <h3 className="mt-3">{t('leagues.askForANewLeague')}</h3>
            <Link className="btn btn-primary" to={"messages"}>
                {t('leagues.submitANewLeagueRequest')}
            </Link>
            <div className="mt-3">
                {userAuth.isAuthenticated && userAuth.roles.filter((r: { value: string; }) => r.value === "admin").length > 0 &&
                    <Button onClick={() => addRandomLeague()}>{t('leagues.addARandomLeague')}</Button>
                }
            </div>
        </Container>
    );
};

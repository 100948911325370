import { useState } from "react";
import { Table, Button } from "react-bootstrap";
import PlayerModel from "../Models/PlayerModel";
import TransferHistoryModel from "../Models/TransferHistoryModel";
import { useTranslation } from "react-i18next";
import { PlayerDetailModal } from "./PlayerDetailModal";

export const TransferHistoryTable: React.FC<{
    transferHistories: TransferHistoryModel[];
}> = (props) => {

    const { t } = useTranslation();

    const [showPlayerModal, setShowPlayerModal] = useState(false);
    const [chosenPlayer, setChosenPlayer] = useState<PlayerModel | null>(null);

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{(t('transferHistoryTable.#'))}</th>
                        <th>{(t('transferHistoryTable.name'))}</th>
                        <th>{(t('transferHistoryTable.age'))}</th>
                        <th>{(t('transferHistoryTable.nationality'))}</th>
                        <th>{(t('transferHistoryTable.position'))}</th>
                        <th>{(t('transferHistoryTable.fit'))}</th>
                        <th>{(t('transferHistoryTable.morale'))}</th>
                        <th>{(t('transferHistoryTable.goals'))}</th>
                        <th>{(t('transferHistoryTable.value'))}</th>
                        <th>{(t('transferHistoryTable.price'))}</th>
                        <th>{(t('transferHistoryTable.from'))}</th>
                        <th>{(t('transferHistoryTable.to'))}</th>
                        <th>{(t('transferHistoryTable.played'))}</th>
                        <th>{(t('transferHistoryTable.date'))}</th>
                        <th>{(t('transferHistoryTable.rating'))}</th>
                        <th>{(t('transferHistoryTable.detail'))}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.transferHistories.map((transferHistory, i) => {
                        return (
                            <tr key={transferHistory.id}>
                                <td>{transferHistory.player.jerseyNumber}</td>
                                <td>{transferHistory.player.name + " " + transferHistory.player.surname}</td>
                                <td>{transferHistory.player.age}</td>
                                <td>{transferHistory.player.nationality}</td>
                                <td>{transferHistory.player.position}</td>
                                <td>{transferHistory.player.fit}</td>
                                <td>{transferHistory.player.morale}</td>
                                <td>{transferHistory.player.goal}</td>
                                <td>{transferHistory.player.formattedValue}</td>
                                <td>{transferHistory.player.transferPrice.toLocaleString(undefined)}</td>
                                <td>{transferHistory.sellerTeam.name + " - " + transferHistory.sellerTeam.user.username}</td>
                                <td>{transferHistory.buyerTeam.name ? transferHistory.buyerTeam.name + " - " + transferHistory.buyerTeam.user.username : transferHistory.buyerTeamName}</td>
                                <td>{transferHistory.player.playedMatches}</td>
                                <td>{transferHistory.transferDate}</td>
                                <td>{transferHistory.player.overallRating}</td>
                                <td><Button onClick={() => {
                                    setChosenPlayer(transferHistory.player);
                                    setShowPlayerModal(true);
                                }}>{(t('transferHistoryTable.detail'))}</Button></td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <PlayerDetailModal showModal={showPlayerModal} setShowModal={() => setShowPlayerModal(false)} chosenPlayer={chosenPlayer} ></PlayerDetailModal>
        </div>
    );
};

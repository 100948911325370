import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ErrorModal: React.FC<{
    error: any;
    isShown: boolean;
    close: any;
}> = (props) => {

    const { t } = useTranslation();

    function getOnClickAction(error: any): void {
        if (error.code === "000020") {
            window.location.replace(process.env.REACT_APP_SITE_URL + "/myTeams");
        } else {
            return props.close;
        }
    }

    return (
        <Modal show={props.isShown} onHide={props.close}>
            <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">{(t('errorModal.anErrorOccurred'))}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className="text-center">
                    {props.error.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => getOnClickAction(props.error)}>
                    {(t('common.toMyTeams'))}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

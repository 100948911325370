import { Row } from "react-bootstrap";
import TeamModel from "../Models/TeamModel";
import { FormationPlayer } from "./FormationPlayer";
import { useTranslation } from "react-i18next";

export const Lineup: React.FC<{
    player11: number | undefined;
    player10: number | undefined;
    player9: number | undefined;
    player8: number | undefined;
    player7: number | undefined;
    player6: number | undefined;
    player5: number | undefined;
    player4: number | undefined;
    player3: number | undefined;
    player2: number | undefined;
    player1: number | undefined;
    validatePlayerId: any;
    setPlayer11: any;
    setPlayer10: any;
    setPlayer9: any;
    setPlayer8: any;
    setPlayer7: any;
    setPlayer6: any;
    setPlayer5: any;
    setPlayer4: any;
    setPlayer3: any;
    setPlayer2: any;
    setPlayer1: any;
    team: TeamModel;
    formationId: number;
}> = (props) => {

    const { t } = useTranslation();

    if (props.formationId === 0) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 1) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 2) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 3) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 4) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 5) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 6) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 7) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 8) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 9) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 10) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 11) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 12) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 13) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 14) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    } else if (props.formationId === 15) {
        return (
            <div>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player11} playerLabel={t('lineup.player11')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer11} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player10} playerLabel={t('lineup.player10')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer10} team={props.team} />
                    <FormationPlayer playerId={props.player9} playerLabel={t('lineup.player9')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer9} team={props.team} />
                    <FormationPlayer playerId={props.player8} playerLabel={t('lineup.player8')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer8} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center mb-3">
                    <FormationPlayer playerId={props.player7} playerLabel={t('lineup.player7')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer7} team={props.team} />
                    <FormationPlayer playerId={props.player6} playerLabel={t('lineup.player6')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer6} team={props.team} />
                    <FormationPlayer playerId={props.player5} playerLabel={t('lineup.player5')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer5} team={props.team} />
                    <FormationPlayer playerId={props.player4} playerLabel={t('lineup.player4')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer4} team={props.team} />
                    <FormationPlayer playerId={props.player3} playerLabel={t('lineup.player3')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer3} team={props.team} />
                    <FormationPlayer playerId={props.player2} playerLabel={t('lineup.player2')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer2} team={props.team} />
                </Row>
                <Row className="justify-content-md-center text-center">
                    <FormationPlayer playerId={props.player1} playerLabel={t('lineup.player1')} validatePlayerId={props.validatePlayerId} setPlayer={props.setPlayer1} team={props.team} />
                </Row>
            </div>
        );
    }

    return (
        <div></div>
    );
};

import { useState } from "react";
import { Container, Card, Row, Col, Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { ErrorModal } from "./ErrorModal";
import { LoadingModal } from "./LoadingModal";
import { MatchModal } from "./MatchModal";
import LeagueModel from "../Models/LeagueModel";
import MatchModel from "../Models/MatchModel";
import TeamModel from "../Models/TeamModel";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TLError } from "../Utility/TLError";

export const LeagueTable: React.FC<{
    setShowMatchModal: any;
    showMatchModal: boolean;
    match: MatchModel | null;
    simulateMatch: any;
    league: LeagueModel | undefined;
    assignTeam: any;
    assignTeamAllowed: boolean;
    askForNewLeagueSection: boolean;
    playable: boolean;
}> = (props) => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [totalLeagueValueHovered, setTotalLeagueValueHovered] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    async function addRandomLeague() {

        setIsLoading(true);
        try {

            await ApiCall(
                ENDPOINTS.addRandomLeague.action,
                ENDPOINTS.addRandomLeague.method,
                userAuth.token,
            );

            setIsLoading(false);
            setError(new TLError("", ""));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    }

    function getTakeThisTeam(team: TeamModel) {
        if (props.league?.status === "P") {
            return t('leagueTable.leagueIsOver');
        }
        if (userAuth.isAuthenticated && team.user.username) {
            return (team.user.username);
        }
        else if (props.league?.teams.filter(t => t.user.id === userAuth.id)[0]) {
            return ("");
        }
        else if (userAuth.isAuthenticated && props.assignTeamAllowed) {
            return (
                <Button onClick={() => props.assignTeam(team.id)}>Take this team</Button>
            );
        } else {
            return (
                <Link to={"/login"} className="btn btn-primary">
                    Sign in
                </Link>
            );
        }
    }

    function getAverageTeamRating(team: TeamModel) {
        let rating = 0;
        rating += team.tactic.player1.overallRating || 0;
        rating += team.tactic.player2.overallRating || 0;
        rating += team.tactic.player3.overallRating || 0;
        rating += team.tactic.player4.overallRating || 0;
        rating += team.tactic.player5.overallRating || 0;
        rating += team.tactic.player6.overallRating || 0;
        rating += team.tactic.player7.overallRating || 0;
        rating += team.tactic.player8.overallRating || 0;
        rating += team.tactic.player9.overallRating || 0;
        rating += team.tactic.player10.overallRating || 0;
        rating += team.tactic.player11.overallRating || 0;
        rating = rating / 11;
        return rating;
    }

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            {props.league &&
                <div key={props.league.id}>
                    <Card className="mt-3 shadow p-3 mb-3 bg-body rounded">
                        <Row className="g-0">
                            <Col>
                                <Table striped bordered hover responsive >
                                    <thead>
                                        <tr>
                                            <th>{t('leagueTable.#')}</th>
                                            <th>{t('leagueTable.name')}</th>
                                            <th>{t('leagueTable.matchesPlayed')}</th>
                                            <th>{t('leagueTable.win')}</th>
                                            <th>{t('leagueTable.draw')}</th>
                                            <th>{t('leagueTable.lose')}</th>
                                            <th>{t('leagueTable.goalForward')}</th>
                                            <th>{t('leagueTable.goalAgainst')}</th>
                                            <th>{t('leagueTable.goalDifference')}</th>
                                            <th>{t('leagueTable.points')}</th>
                                            <th>{t('leagueTable.teamValue')}</th>
                                            <th>{t('leagueTable.rating')}</th>
                                            <th>{t('leagueTable.detail')}</th>
                                            <th>{t('leagueTable.user')}</th>
                                            {props.playable && userAuth.id && (props.league.teams.filter(t => t.user.id === userAuth.id)[0]) && <th>{t('leagueTable.match')}</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.league.teams.map((team, i) => {

                                            return (
                                                <tr key={team.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{team.name} <img style={{ float: "right" }} src={require("./../Assets/Flags/" + team.code + ".jpg")} width="30" height="20" alt="Default" /></td>
                                                    <td>{team.win + team.draw + team.loss}</td>
                                                    <td>{team.win}</td>
                                                    <td>{team.draw}</td>
                                                    <td>{team.loss}</td>
                                                    <td>{team.goalsFor}</td>
                                                    <td>{team.goalsAgainst}</td>
                                                    <td>{team.goalsFor - team.goalsAgainst}</td>
                                                    <td>{team.point}</td>
                                                    <td>{team.formattedTeamValue}</td>
                                                    <td>{getAverageTeamRating(team).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                    <td><Link className="btn btn-primary" to={"/teams/" + team.id}>{t('leagueTable.squad')}</Link></td>
                                                    <td>{getTakeThisTeam(team)}</td>
                                                    {props.playable &&
                                                        <td>
                                                            {
                                                                userAuth.id && (userAuth.id !== team.user.id && props.league?.teams.filter(t => t.user.id === userAuth.id)[0]) && !userAuth.isAuthenticated ?
                                                                    <Link to={"/login"} className="btn btn-primary">
                                                                        {t('leagueTable.signIn')}
                                                                    </Link>
                                                                    :
                                                                    userAuth.id && (userAuth.id !== team.user.id && props.league?.teams.filter(t => t.user.id === userAuth.id)[0]) ?
                                                                        <Button onClick={() => props.simulateMatch(props.league?.teams.filter(t => t.user.id === userAuth.id)[0].id, team.id)}>{t('leagueTable.play')}</Button>
                                                                        :
                                                                        ""
                                                            }
                                                        </td>}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col md={3}>
                                <Card.Body style={{ color: "black" }}>
                                    <h4>{props.league.name}</h4>
                                    <Card.Title > {t('leagueTable.totalTeams')} {props.league.teams.length} </Card.Title>
                                    <hr />
                                    <Card.Text onMouseEnter={() => setTotalLeagueValueHovered(true)} onMouseLeave={() => setTotalLeagueValueHovered(false)}>
                                        {" "}
                                        {t('leagueTable.totalLeagueValue')} {totalLeagueValueHovered ? props.league.leagueValue.toLocaleString(undefined, { minimumFractionDigits: 2 }) : props.league.formattedLeagueValue}
                                    </Card.Text>
                                    <Card.Text>
                                        {" "}
                                        {t('leagueTable.leagueCreatedAt')}
                                        <br />
                                        {moment(props.league.createDate).format("HH:mm:SS DD/MM/YYYY")}
                                    </Card.Text>
                                    <div className="d-none d-lg-block">
                                        <img
                                            src={require("./../Assets/images/football1.jpg")}
                                            width="123"
                                            height="196"
                                            alt="Default"
                                        />
                                    </div>
                                    <div className="d-lg-none d-flex justify-content-center align-items-center">
                                        <img
                                            src={require("./../Assets/images/football2.jpg")}
                                            width="123"
                                            height="196"
                                            alt="Default"
                                        />
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                    <hr />
                    {props.askForNewLeagueSection && <div>
                        <h3 className="mt-3">{t('leagueTable.askForANewLeague')} </h3>
                        <Link className="btn btn-primary" to={"search"}>
                            {t('leagueTable.submitANewLeagueRequest')}
                        </Link>
                        <br />
                        <br />
                        {userAuth.isAuthenticated && userAuth.roles.filter((r: { value: string; }) => r.value === "admin").length > 0 &&
                            <Button onClick={() => addRandomLeague()}>{t('leagueTable.addARandomLeague')}</Button>
                        }
                    </div>
                    }
                </div>
            }
            {props.showMatchModal &&
                <MatchModal match={props.match} showMatchModal={props.showMatchModal} setShowMatchModal={props.setShowMatchModal} />
            }
        </Container >
    );
};

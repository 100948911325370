import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LeagueTable } from "../Components/LeaugeTable";
import { LoadingModal } from "../Components/LoadingModal";
import LeagueModel from "../Models/LeagueModel";
import MatchModel from "../Models/MatchModel";
import TeamModel from "../Models/TeamModel";
import { Wallet } from "../Components/Wallet";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const LeagueStandings = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const teamId = userContext.currentTeamId;

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [teamAssignTriggered, setTeamAssignTriggered] = useState(false);

    const [league, setLeague] = useState<LeagueModel>();

    const [match, setMatch] = useState<MatchModel | null>(null);
    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    const [showMatchModal, setShowMatchModal] = useState(false);

    const [leagueUpdated, setLeagueUpdated] = useState(false);

    useEffect(() => {
        document.title = league?.name ? league?.name + t('title.torndel') : t('title.leagueStandings');
    }, [t, league?.name]);

    useEffect(() => {

        const getLeagues = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getLeagueWithTeamId.action,
                ENDPOINTS.getLeagueWithTeamId.method,
                userAuth.token,
                { teamId: teamId }
            );
            responseJson.league.teams.sort((a: TeamModel, b: TeamModel) => a.point < b.point ? 1 : (a.point > b.point ? -1 : 0));

            setLeague(responseJson.league);
            setError(new TLError("", ""));
            setIsLoading(false);
        };
        getLeagues().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });
    }, [teamId, userAuth.token, teamAssignTriggered, leagueUpdated]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);

    async function assignTeam(teamId: number) {

        setIsLoading(true);
        try {
            await ApiCall(
                ENDPOINTS.assignTeam.action,
                ENDPOINTS.assignTeam.method,
                userAuth.token,
                { teamId: teamId }
            );

            setTeamAssignTriggered(!teamAssignTriggered);
            setIsLoading(false);
            setError(new TLError("", ""));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    }

    async function simulateMatch(homeTeamId: number, awayTeamId: number) {

        setIsLoading(true);
        try {

            const responseJson = await ApiCall(
                ENDPOINTS.simulateMatch.action,
                ENDPOINTS.simulateMatch.method,
                userAuth.token,
                {
                    homeTeamId: homeTeamId,
                    awayTeamId: awayTeamId
                }
            );

            setLeagueUpdated(!leagueUpdated);
            setMatch(responseJson.match);
            setShowMatchModal(true);
            setIsLoading(false);
            setError(new TLError("", ""));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    }

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            {league &&
                <LeagueTable showMatchModal={showMatchModal} setShowMatchModal={setShowMatchModal} match={match} simulateMatch={simulateMatch} league={league} assignTeam={assignTeam} askForNewLeagueSection assignTeamAllowed={false} playable />
            }
        </Container >
    );
};

import { useState } from "react";
import { Alert, Button, Card, Col, Form } from "react-bootstrap";
import MessageModel from "../Models/MessageModel";
import { useTranslation } from "react-i18next";

export const AdminMessage: React.FC<{
    message: MessageModel;
    submitResponseToQuestion: any;
}> = (props) => {

    const { t } = useTranslation();

    const [displayWarning, setDisplayWarning] = useState(false);
    const [response, setResponse] = useState("");

    function submitBtn() {
        if (props.message.id !== null && response !== "") {
            props.submitResponseToQuestion(props.message.id, response);
            setDisplayWarning(false);
        } else {
            setDisplayWarning(true);
        }
    }

    return (
        <div key={props.message.id}>
            <Card className="mt-2 shadow p-3 bg-body rounded">
                <Card.Title>
                    {t('adminPage.adminMessage.case#')}{props.message.id}: {props.message.title}
                </Card.Title>
                <Card.Subtitle className="mb-2 ">
                    {props.message.userEmail}
                </Card.Subtitle>
                <Card.Text>{props.message.question}</Card.Text>
                <div>
                    <Card.Title>{t('adminPage.adminMessage.response')}</Card.Title>
                    <Form action="PUT">
                        {displayWarning && (
                            <Alert key={"danger"} variant={"danger"}>
                                {t('adminPage.adminMessage.allFieldsMustBeFilledOut')}
                            </Alert>
                        )}
                        <Col md={12} mb={3}>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="response"
                                placeholder={t('adminPage.adminMessage.enterResponse')}
                                required
                                onChange={(e) => setResponse(e.target.value)}
                                value={response}
                            />
                        </Col>
                        <Col md={3} mb={3}>
                            <Button variant="primary" className="mt-3" onClick={submitBtn}>
                                {t('adminPage.adminMessage.submitResponse')}
                            </Button>
                        </Col>
                    </Form>
                </div>
            </Card>
        </div>
    );
};

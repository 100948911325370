import { Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import MessageModel from "../Models/MessageModel";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { UrlCreator } from "../Utility/UrlCreator";
import { useAuth } from "../Utility/useAuth";
import { AdminMessage } from "../Components/AdminMessage";
import { TLPagination } from "../Components/TLPagination";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const AdminPage = () => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [messages, setMessages] = useState<MessageModel[]>([]);
    const [messagesPerPage] = useState(5);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const [btnSubmit, setBtnSubmit] = useState(false);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    useEffect(() => {
        document.title = t('title.admin');
    }, [t]);

    useEffect(() => {
        const fetchUserMessages = async () => {

            const action = UrlCreator(ENDPOINTS.getUserMessages.action, [
                { name: "closed", value: false },
                { name: "page", value: currentPage - 1 },
                { name: "size", value: messagesPerPage },
            ]);

            const responseJson = await ApiCall(
                action,
                ENDPOINTS.getUserMessages.method,
                userAuth.token
            );

            setMessages(responseJson._embedded.messages);
            setTotalPages(responseJson.page.totalPages);
            setIsLoading(false);
            setError(new TLError("", ""));
        };
        fetchUserMessages().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

        window.scrollTo(0, 0);

    }, [currentPage, btnSubmit, userAuth.token, messagesPerPage]);

    async function submitResponseToQuestion(id: number, response: string) {
        if (
            userAuth &&
            userAuth?.isAuthenticated &&
            id !== null &&
            response !== ""
        ) {
            await ApiCall(
                ENDPOINTS.submitResponseToQuestion.action,
                ENDPOINTS.submitResponseToQuestion.method,
                userAuth.token,
                {
                    id: id,
                    response: response
                }
            );

            setBtnSubmit(!btnSubmit);
        }
    }

    return (
        <Container className="mt-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <h3>{t('adminPage.title')}</h3>
            {messages.length > 0 ? (
                <div>
                    <h5>{t('adminPage.pendingQA')}</h5>
                    {messages.map((message) => (
                        <AdminMessage
                            message={message}
                            key={message.id}
                            submitResponseToQuestion={submitResponseToQuestion}
                        />
                    ))}
                </div>
            ) : (
                <h5>{t('adminPage.noPendingQA')}</h5>
            )}
            {totalPages > 1 && (
                <TLPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                />
            )}
        </Container>
    );
};

import { useState } from "react";
import { Button, Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { LoadingModal } from "./LoadingModal";
import PlayerModel from "../Models/PlayerModel";
import TeamModel from "../Models/TeamModel";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const Training: React.FC<{
    team: TeamModel;
}> = (props) => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [displayWarning, setDisplayWarning] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [player1, setPlayer1] = useState<number | undefined>(props.team.training.player1.id);
    const [player1TrainingEndDate, setPlayer1TrainingEndDate] = useState(props.team.training.player1TrainingEndDate ? new Date(props.team.training.player1TrainingEndDate) : "");

    const [player2, setPlayer2] = useState<number | undefined>(props.team.training.player2.id);
    const [player2TrainingEndDate, setPlayer2TrainingEndDate] = useState(props.team.training.player2TrainingEndDate ? new Date(props.team.training.player2TrainingEndDate) : "");

    const [player3, setPlayer3] = useState<number | undefined>(props.team.training.player3.id);
    const [player3TrainingEndDate, setPlayer3TrainingEndDate] = useState(props.team.training.player3TrainingEndDate ? new Date(props.team.training.player3TrainingEndDate) : "");

    const [player4, setPlayer4] = useState<number | undefined>(props.team.training.player4.id);
    const [player4TrainingEndDate, setPlayer4TrainingEndDate] = useState(props.team.training.player4TrainingEndDate ? new Date(props.team.training.player4TrainingEndDate) : "");

    const [player5, setPlayer5] = useState<number | undefined>(props.team.training.player5.id);
    const [player5TrainingEndDate, setPlayer5TrainingEndDate] = useState(props.team.training.player5TrainingEndDate ? new Date(props.team.training.player5TrainingEndDate) : "");

    const [player6, setPlayer6] = useState<number | undefined>(props.team.training.player6.id);
    const [player6TrainingEndDate, setPlayer6TrainingEndDate] = useState(props.team.training.player6TrainingEndDate ? new Date(props.team.training.player6TrainingEndDate) : "");

    const [lastTrainedPlayer, setLastTrainedPlayer] = useState<PlayerModel>();
    const [addedTrainingPercentage, setAddedTrainingPercentage] = useState();

    const [displaySuccessMessage, setDisplaySuccessMessage] = useState(t('common.success'));

    async function startTraining(playerId: number | undefined, slot: number) {

        setDisplayWarning("");
        setIsLoading(true);
        try {
            const responseJson = await ApiCall(
                ENDPOINTS.startTraining.action,
                ENDPOINTS.startTraining.method,
                userAuth.token,
                {
                    playerId: playerId,
                    slot: slot
                }
            );

            setPlayer1(responseJson.trainingDTO.player1.id ? responseJson.trainingDTO.player1.id : 0);
            setPlayer2(responseJson.trainingDTO.player2.id ? responseJson.trainingDTO.player2.id : 0);
            setPlayer3(responseJson.trainingDTO.player3.id ? responseJson.trainingDTO.player3.id : 0);
            setPlayer4(responseJson.trainingDTO.player4.id ? responseJson.trainingDTO.player4.id : 0);
            setPlayer5(responseJson.trainingDTO.player5.id ? responseJson.trainingDTO.player5.id : 0);
            setPlayer6(responseJson.trainingDTO.player6.id ? responseJson.trainingDTO.player6.id : 0);
            setPlayer1TrainingEndDate(responseJson.trainingDTO.player1TrainingEndDate ? new Date(responseJson.trainingDTO.player1TrainingEndDate) : "");
            setPlayer2TrainingEndDate(responseJson.trainingDTO.player2TrainingEndDate ? new Date(responseJson.trainingDTO.player2TrainingEndDate) : "");
            setPlayer3TrainingEndDate(responseJson.trainingDTO.player3TrainingEndDate ? new Date(responseJson.trainingDTO.player3TrainingEndDate) : "");
            setPlayer4TrainingEndDate(responseJson.trainingDTO.player4TrainingEndDate ? new Date(responseJson.trainingDTO.player4TrainingEndDate) : "");
            setPlayer5TrainingEndDate(responseJson.trainingDTO.player5TrainingEndDate ? new Date(responseJson.trainingDTO.player5TrainingEndDate) : "");
            setPlayer6TrainingEndDate(responseJson.trainingDTO.player6TrainingEndDate ? new Date(responseJson.trainingDTO.player6TrainingEndDate) : "");

            setDisplaySuccessMessage(t('common.success'));
            setIsLoading(false);
            setDisplaySuccess(true);
            setDisplayWarning("");
        } catch (error: any) {
            setIsLoading(false);
            setDisplaySuccess(false);
            setDisplayWarning(error.message);
        }
    };

    async function endTraining(playerId: number | undefined, slot: number) {

        setDisplayWarning("");
        setIsLoading(true);
        try {
            const responseJson = await ApiCall(
                ENDPOINTS.endTraining.action,
                ENDPOINTS.endTraining.method,
                userAuth.token,
                {
                    playerId: playerId,
                    slot: slot
                }
            );
            setPlayer1(responseJson.trainingDTO.player1.id ? responseJson.trainingDTO.player1.id : 0);
            setPlayer2(responseJson.trainingDTO.player2.id ? responseJson.trainingDTO.player2.id : 0);
            setPlayer3(responseJson.trainingDTO.player3.id ? responseJson.trainingDTO.player3.id : 0);
            setPlayer4(responseJson.trainingDTO.player4.id ? responseJson.trainingDTO.player4.id : 0);
            setPlayer5(responseJson.trainingDTO.player5.id ? responseJson.trainingDTO.player5.id : 0);
            setPlayer6(responseJson.trainingDTO.player6.id ? responseJson.trainingDTO.player6.id : 0);
            setPlayer1TrainingEndDate(responseJson.trainingDTO.player1TrainingEndDate ? new Date(responseJson.trainingDTO.player1TrainingEndDate) : "");
            setPlayer2TrainingEndDate(responseJson.trainingDTO.player2TrainingEndDate ? new Date(responseJson.trainingDTO.player2TrainingEndDate) : "");
            setPlayer3TrainingEndDate(responseJson.trainingDTO.player3TrainingEndDate ? new Date(responseJson.trainingDTO.player3TrainingEndDate) : "");
            setPlayer4TrainingEndDate(responseJson.trainingDTO.player4TrainingEndDate ? new Date(responseJson.trainingDTO.player4TrainingEndDate) : "");
            setPlayer5TrainingEndDate(responseJson.trainingDTO.player5TrainingEndDate ? new Date(responseJson.trainingDTO.player5TrainingEndDate) : "");
            setPlayer6TrainingEndDate(responseJson.trainingDTO.player6TrainingEndDate ? new Date(responseJson.trainingDTO.player6TrainingEndDate) : "");

            setAddedTrainingPercentage(responseJson.addedTrainingPercentage);
            setLastTrainedPlayer(responseJson.playerDTO);

            setDisplaySuccessMessage(t('training.success') + lastTrainedPlayer?.name + " " + lastTrainedPlayer?.surname + t('training.gained') + addedTrainingPercentage + t('training.trainingPoints'));

            setIsLoading(false);
            setDisplaySuccess(true);
            setDisplayWarning("");
        } catch (error: any) {
            setIsLoading(false);
            setDisplaySuccess(false);
            setDisplayWarning(error.message);
        }
    };

    function validatePlayerId(value: string) {
        if (player1 === parseInt(value)) {
            setPlayer1(0);
        }
        if (player2 === parseInt(value)) {
            setPlayer2(0);
        }
        if (player3 === parseInt(value)) {
            setPlayer3(0);
        }
        if (player4 === parseInt(value)) {
            setPlayer4(0);
        }
        if (player5 === parseInt(value)) {
            setPlayer5(0);
        }
        if (player6 === parseInt(value)) {
            setPlayer6(0);
        }
    }

    return (
        <Container className="mt-2 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {displaySuccessMessage}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Card className="mb-3">
                <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
                <Card.Header>{t('training.title')}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{player1 && player1TrainingEndDate ? <Countdown date={moment(player1TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player1TrainingEndDate !== ""}
                                    value={player1}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer1(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player2 && p.id !== player3 && p.id !== player4 && p.id !== player5 && p.id !== player6)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player1 && player1TrainingEndDate ? endTraining(player1, 1) : startTraining(player1, 1);
                                    }}                                >
                                    {player1 && player1TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{player2 && player2TrainingEndDate ? <Countdown date={moment(player2TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player2TrainingEndDate !== ""}
                                    value={player2}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer2(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player1 && p.id !== player3 && p.id !== player4 && p.id !== player5 && p.id !== player6)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player2 && player2TrainingEndDate ? endTraining(player2, 2) : startTraining(player2, 2);
                                    }}                                >
                                    {player2 && player2TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{player3 && player3TrainingEndDate ? <Countdown date={moment(player3TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player3TrainingEndDate !== ""}
                                    value={player3}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer3(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player1 && p.id !== player2 && p.id !== player4 && p.id !== player5 && p.id !== player6)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player3 && player3TrainingEndDate ? endTraining(player3, 3) : startTraining(player3, 3);
                                    }}                                >
                                    {player3 && player3TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3} className="align-center">
                                <Form.Label>{player4 && player4TrainingEndDate ? <Countdown date={moment(player4TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player4TrainingEndDate !== ""}
                                    value={player4}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer4(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player1 && p.id !== player2 && p.id !== player3 && p.id !== player5 && p.id !== player6)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player4 && player4TrainingEndDate ? endTraining(player4, 4) : startTraining(player4, 4);
                                    }}                                >
                                    {player4 && player4TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} className="align-center">
                                <Form.Label>{player5 && player5TrainingEndDate ? <Countdown date={moment(player5TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player5TrainingEndDate !== ""}
                                    value={player5}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer5(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player1 && p.id !== player2 && p.id !== player3 && p.id !== player4 && p.id !== player6)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player5 && player5TrainingEndDate ? endTraining(player5, 5) : startTraining(player5, 5);
                                    }}                                >
                                    {player5 && player5TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{player6 && player6TrainingEndDate ? <Countdown date={moment(player6TrainingEndDate).toDate()} /> : t('training.trainAPlayer')}</Form.Label>
                                <Form.Select
                                    disabled={player6TrainingEndDate !== ""}
                                    value={player6}
                                    onChange={(e) => {
                                        validatePlayerId(e.target.value);
                                        setPlayer6(parseInt(e.target.value));
                                    }}>
                                    <option key={"0"} value="0">{t('training.selectAPlayer')}</option>
                                    {props.team.players.filter(p => (p.id !== player1 && p.id !== player2 && p.id !== player3 && p.id !== player4 && p.id !== player5)).map(p =>
                                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                    )}
                                </Form.Select>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        player6 && player6TrainingEndDate ? endTraining(player6, 6) : startTraining(player6, 6);
                                    }}                                >
                                    {player6 && player6TrainingEndDate ? t('training.completeTraining') : t('training.train')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

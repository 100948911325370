import { useState } from "react";
import { Table, Modal, Button, ProgressBar, ListGroup, Form } from "react-bootstrap";
import { LabelDataPair } from "./LabelDataPair";
import PlayerModel from "../Models/PlayerModel";
import { useTranslation } from "react-i18next";
import { PlayerDetailModal } from "./PlayerDetailModal";

export const SquadTable: React.FC<{
    players: PlayerModel[];
    putPlayerOnTransferList: any;
    removePlayerFromTransferList: any;
}> = (props) => {

    const { t } = useTranslation();

    const [showPlayerModal, setShowPlayerModal] = useState(false);
    const [chosenPlayer, setChosenPlayer] = useState<PlayerModel | null>(null);
    const [isTransferPopupAvailable, setIsTransferPopupAvailable] = useState(false);
    const [transferPrice, setTransferPrice] = useState(0);

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{(t('squadTable.#'))}</th>
                        <th>{(t('squadTable.name'))}</th>
                        <th>{(t('squadTable.age'))}</th>
                        <th>{(t('squadTable.nationality'))}</th>
                        <th>{(t('squadTable.position'))}</th>
                        <th>{(t('squadTable.fit'))}</th>
                        <th>{(t('squadTable.morale'))}</th>
                        <th>{(t('squadTable.status'))}</th>
                        <th>{(t('squadTable.attack'))}</th>
                        <th>{(t('squadTable.defense'))}</th>
                        <th>{(t('squadTable.rating'))}</th>
                        <th>{(t('squadTable.value'))}</th>
                        <th>{(t('squadTable.transfer'))}</th>
                        <th>{(t('squadTable.detail'))}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.players.map((player, i) => {
                        return (
                            <tr key={player.id}>
                                <td>{player.jerseyNumber}</td>
                                <td>{player.name + " " + player.surname}</td>
                                <td>{player.age}</td>
                                <td>{player.nationality}</td>
                                <td>{player.position}</td>
                                <td>{player.fit}</td>
                                <td>{player.morale}</td>
                                <td>{player.status + (player.remainingStatusDay ? " - " + player.remainingStatusDay : "")}</td>
                                <td>{player.attack}</td>
                                <td>{player.defense}</td>
                                <td>{player.overallRating}</td>
                                <td>{player.formattedValue}</td>
                                <td>{player.isOnTransferList ?
                                    <Button onClick={() => props.removePlayerFromTransferList(player.id)}> {t('squadTable.remove')} </Button>
                                    :
                                    <Button onClick={() => {
                                        setChosenPlayer(player);
                                        setIsTransferPopupAvailable(true);
                                        setTransferPrice(player.value);
                                    }}> {(t('squadTable.sell'))}</Button>}</td>
                                <td><Button onClick={() => {
                                    setChosenPlayer(player);
                                    setShowPlayerModal(true);
                                }}>{(t('squadTable.detail'))}</Button></td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <PlayerDetailModal showModal={showPlayerModal} setShowModal={() => setShowPlayerModal(false)} chosenPlayer={chosenPlayer} />
            {chosenPlayer &&
                <Modal show={isTransferPopupAvailable} onHide={() => { setIsTransferPopupAvailable(false); }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{chosenPlayer.name + " " + chosenPlayer.surname}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            {t('squadTable.training')}
                            <ProgressBar animated now={50} />
                        </div>
                        <ListGroup>
                            <LabelDataPair label={t('squadTable.#')} value={chosenPlayer.jerseyNumber} />
                            <LabelDataPair label={t('squadTable.age')} value={chosenPlayer.age} />
                            <LabelDataPair label={t('squadTable.birthDate')} value={chosenPlayer.birthDate} />
                            <LabelDataPair label={t('squadTable.nationality')} value={chosenPlayer.nationality} />
                            <LabelDataPair label={t('squadTable.position')} value={chosenPlayer.position} />
                            <LabelDataPair label={t('squadTable.fit')} value={chosenPlayer.fit} />
                            <LabelDataPair label={t('squadTable.morale')} value={chosenPlayer.morale} />
                            <LabelDataPair label={t('squadTable.goals')} value={chosenPlayer.goal} />
                            <LabelDataPair label={t('squadTable.assists')} value={chosenPlayer.assist} />
                            <LabelDataPair label={t('squadTable.value')} value={chosenPlayer.formattedValue} />
                        </ListGroup>
                        <div className="mb-3 mt-3">
                            {t('squadTable.transferPrice')} {transferPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                            <Form.Range
                                min={chosenPlayer.minValue}
                                max={chosenPlayer.maxValue}
                                value={transferPrice}
                                onChange={(e) => {
                                    setTransferPrice(parseInt(e.target.value));
                                }} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="justify-content-between">
                        <Button variant="primary" onClick={() => {
                            props.putPlayerOnTransferList(chosenPlayer.id, transferPrice);
                            setIsTransferPopupAvailable(false);
                        }}>
                            {t('squadTable.sell')}
                        </Button>
                        <Button variant="secondary" onClick={() => { setIsTransferPopupAvailable(false); }}>
                            {t('common.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    );
};

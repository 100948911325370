import { useEffect, useState } from "react";
import { Container, Alert, Card, Form, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { SpinnerLoading } from "../Components/SpinnerLoading";
import { useTranslation } from "react-i18next";

const RegisterPage = () => {

    const { t } = useTranslation();

    const history = useHistory();

    const [displayWarning, setDisplayWarning] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const [formValidated, setFormValidated] = useState(false);

    const [name, setName] = useState("");
    const [nameValidation, setNameValidation] = useState<boolean | undefined>(undefined);
    const [nameValidationMessage, setNameValidationMessage] = useState("");

    const [surname, setSurname] = useState("");
    const [surnameValidation, setSurnameValidation] = useState<boolean | undefined>(undefined);
    const [surnameValidationMessage, setSurnameValidationMessage] = useState("");

    const [username, setUsername] = useState("");
    const [usernameValidation, setUsernameValidation] = useState<boolean | undefined>(undefined);
    const [usernameValidationMessage, setUsernameValidationMessage] = useState("");

    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState<boolean | undefined>(undefined);
    const [emailValidationMessage, setEmailValidationMessage] = useState("");

    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState<boolean | undefined>(undefined);
    const [passwordValidationMessage, setPasswordValidationMessage] = useState("");

    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationValidation, setPasswordConfirmationValidation] = useState<boolean | undefined>(undefined);
    const [passwordConfirmationValidationMessage, setPasswordConfirmationValidationMessage] = useState("");

    const nameOnChange = (value: string) => {
        setName(value);
        setFormValidated(false);
        if (value) {
            setNameValidation(true);
            setNameValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setNameValidation(false);
            setNameValidationMessage(t('registerPage.nameFailedValidation'));
        }
    };

    const surnameOnChange = (value: string) => {
        setSurname(value);
        setFormValidated(false);
        if (value) {
            setSurnameValidation(true);
            setSurnameValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setSurnameValidation(false);
            setSurnameValidationMessage(t('registerPage.surnameFailedValidation'));
        }
    };

    const usernameOnChange = (value: string) => {
        setUsername(value);
        setFormValidated(false);
        if (value && value.length >= 2) {
            setUsernameValidation(true);
            setUsernameValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setUsernameValidation(false);
            setUsernameValidationMessage(t('registerPage.usernameFailedValidation'));
        }
    };

    const emailOnChange = (value: string) => {
        setEmail(value);
        setFormValidated(false);
        if (value && EMAIL_REGEX.test(value)) {
            setEmailValidation(true);
            setEmailValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setEmailValidation(false);
            setEmailValidationMessage(t('registerPage.emailFailedValidation'));
        }
    };

    const passwordOnChange = (value: string) => {
        setPassword(value);
        setFormValidated(false);
        if (value && value.length >= 8) {
            setPasswordValidation(true);
            setPasswordValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setPasswordValidation(false);
            setPasswordValidationMessage(t('registerPage.passwordFailedValidation'));
        }

        if (value && value.length === passwordConfirmation.length) {
            setPasswordConfirmationValidation(true);
            setPasswordConfirmationValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setPasswordConfirmationValidation(false);
            setPasswordConfirmationValidationMessage(t('registerPage.passwordConfirmationFailedValidation'));
        }
    };

    const passwordConfirmationOnChange = (value: string) => {
        setPasswordConfirmation(value);
        setFormValidated(false);
        if (value && value.length === password.length) {
            setPasswordConfirmationValidation(true);
            setPasswordConfirmationValidationMessage(t('registerPage.successfulValidation'));
        } else {
            setPasswordConfirmationValidation(false);
            setPasswordConfirmationValidationMessage(t('registerPage.passwordConfirmationFailedValidation'));
        }
    };

    async function register() {
        nameOnChange(name);
        surnameOnChange(surname);
        usernameOnChange(username);
        emailOnChange(email);
        passwordOnChange(password);
        passwordConfirmationOnChange(passwordConfirmation);

        if (!nameValidation || !surnameValidation || !usernameValidation || !emailValidation || !passwordValidation || !passwordConfirmationValidation) {
            setDisplayWarning(t('registerPage.missingFieldsValidation'));
        } else {
            setDisplayWarning("");
            setIsLoading(true);

            const crypto = require("crypto-js");
            const hashedPassword = crypto.MD5(password).toString();
            const hashedPasswordConfirmation = crypto.MD5(password).toString();

            try {
                const responseJson = await ApiCall(
                    ENDPOINTS.register.action,
                    ENDPOINTS.register.method,
                    "",
                    {
                        name: name,
                        surname: surname,
                        username: username,
                        email: email,
                        password: hashedPassword,
                        passwordConfirmation: hashedPasswordConfirmation
                    }
                );

                localStorage.setItem("token", responseJson.token);
                setIsLoading(false);
                setDisplayWarning("");
                setDisplaySuccess(true);
                history.push("/myTeams");
                window.location.reload();
            } catch (error: any) {
                setIsLoading(false);
                setDisplayWarning(error.message);
                setDisplaySuccess(false);
            }
        }
    };

    useEffect(() => {
        document.title = t('title.register');
    }, [t]);

    return (
        <Container className="mt-5 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {t('registerPage.successLoadingHomePage')}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Card>
                {isLoading && <SpinnerLoading />}
                <Card.Header>{t('registerPage.register')}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate validated={formValidated}>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3} >
                                <Form.Group as={Col} controlId="validationCustom01">
                                    <Form.Label>{t('registerPage.name')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={t('registerPage.name')}
                                        value={name}
                                        required
                                        onChange={(e) => nameOnChange(e.target.value)}
                                        isInvalid={nameValidation !== undefined ? !nameValidation : undefined}
                                        isValid={nameValidation !== undefined ? nameValidation : undefined}
                                    />
                                    <Form.Control.Feedback type={nameValidation ? "valid" : "invalid"}>{nameValidationMessage}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{t('registerPage.surname')}</Form.Label>
                                <Form.Control
                                    id="surname"
                                    type="text"
                                    name="surname"
                                    placeholder={t('registerPage.surname')}
                                    value={surname}
                                    onChange={(e) => surnameOnChange(e.target.value)}
                                    isInvalid={surnameValidation !== undefined ? !surnameValidation : undefined}
                                    isValid={surnameValidation !== undefined ? surnameValidation : undefined}
                                />
                                <Form.Control.Feedback type={surnameValidation ? "valid" : "invalid"}>{surnameValidationMessage}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <br />
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{t('registerPage.username')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder={t('registerPage.username')}
                                    value={username}
                                    onChange={(e) => usernameOnChange(e.target.value)}
                                    isInvalid={usernameValidation !== undefined ? !usernameValidation : undefined}
                                    isValid={usernameValidation !== undefined ? usernameValidation : undefined}
                                />{usernameValidation !== undefined &&
                                    <Form.Control.Feedback type={usernameValidation ? "valid" : "invalid"}>{usernameValidationMessage}</Form.Control.Feedback>
                                }
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{t('registerPage.email')}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder={t('registerPage.email')}
                                    value={email}
                                    onChange={(e) => emailOnChange(e.target.value)}
                                    isInvalid={emailValidation !== undefined ? !emailValidation : undefined}
                                    isValid={emailValidation !== undefined ? emailValidation : undefined}
                                />
                                <Form.Control.Feedback type={emailValidation ? "valid" : "invalid"}>{emailValidationMessage}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <br />
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{t('registerPage.password')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={t('registerPage.password')}
                                    value={password}
                                    onChange={(e) => passwordOnChange(e.target.value)}
                                    isInvalid={passwordValidation !== undefined ? !passwordValidation : undefined}
                                    isValid={passwordValidation !== undefined ? passwordValidation : undefined}
                                />
                                <Form.Control.Feedback type={passwordValidation ? "valid" : "invalid"}>{passwordValidationMessage}</Form.Control.Feedback>
                                <Form.Text id="passwordHelpBlock" muted>
                                    {t('registerPage.passwordMustBeAtLeastEightCharacters')}
                                </Form.Text>
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{t('registerPage.passwordConfirmation')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="passwordConfirmation"
                                    placeholder={t('registerPage.writeYourPasswordAgain')}
                                    value={passwordConfirmation}
                                    onChange={(e) => passwordConfirmationOnChange(e.target.value)}
                                    isInvalid={passwordConfirmationValidation !== undefined ? !passwordConfirmationValidation : undefined}
                                    isValid={passwordConfirmationValidation !== undefined ? passwordConfirmationValidation : undefined}
                                />
                                <Form.Control.Feedback type={passwordConfirmationValidation ? "valid" : "invalid"}>{passwordConfirmationValidationMessage}</Form.Control.Feedback>
                                <Form.Text id="passwordHelpBlock" muted>
                                    {t('registerPage.passwordAndConfirmationMustMatch')}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => register()}
                                >
                                    {t('registerPage.registerButton')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default RegisterPage;

import { useState } from "react";
import { Table, Button, } from "react-bootstrap";
import PlayerModel from "../Models/PlayerModel";
import TeamModel from "../Models/TeamModel";
import { PlayerDetailModal } from "./PlayerDetailModal";
import { useTranslation } from "react-i18next";

export const TransferListTable: React.FC<{
    buyPlayer: any;
    players: PlayerModel[];
    team: TeamModel | undefined;
    removePlayerFromTransferList: any;
}> = (props) => {

    const { t } = useTranslation();

    const [showPlayerModal, setShowPlayerModal] = useState(false);
    const [chosenPlayer, setChosenPlayer] = useState<PlayerModel | null>(null);

    return (
        <div>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>{(t('transferListTable.#'))}</th>
                        <th>{(t('transferListTable.name'))}</th>
                        <th>{(t('transferListTable.age'))}</th>
                        <th>{(t('transferListTable.nationality'))}</th>
                        <th>{(t('transferListTable.position'))}</th>
                        <th>{(t('transferListTable.fit'))}</th>
                        <th>{(t('transferListTable.morale'))}</th>
                        <th>{(t('transferListTable.goals'))}</th>
                        <th>{(t('transferListTable.value'))}</th>
                        <th>{(t('transferListTable.price'))}</th>
                        <th>{(t('transferListTable.status'))}</th>
                        <th>{(t('transferListTable.played'))}</th>
                        <th>{(t('transferListTable.rating'))}</th>
                        <th>{(t('transferListTable.transfer'))}</th>
                        <th>{(t('transferListTable.detail'))}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.players.map((player, i) => {
                        return (
                            <tr key={player.id}>
                                <td>{player.jerseyNumber}</td>
                                <td>{player.name + " " + player.surname}</td>
                                <td>{player.age}</td>
                                <td>{player.nationality}</td>
                                <td>{player.position}</td>
                                <td>{player.fit}</td>
                                <td>{player.morale}</td>
                                <td>{player.goal}</td>
                                <td>{player.formattedValue}</td>
                                <td>{player.formattedTransferPrice}</td>
                                <td>{player.status}</td>
                                <td>{player.playedMatches}</td>
                                <td>{player.overallRating}</td>
                                <td>{player.team.id !== props.team?.id
                                    ?
                                    <Button onClick={() => (props.buyPlayer(player.id))}> {(t('transferListTable.buy'))}</Button>
                                    :
                                    <Button onClick={() => props.removePlayerFromTransferList(player.id)}> {(t('transferListTable.remove'))} </Button>}
                                </td>
                                <td>
                                    <Button onClick={() => {
                                        setChosenPlayer(player);
                                        setShowPlayerModal(true);
                                    }}>{(t('transferListTable.detail'))}
                                    </Button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
            <PlayerDetailModal showModal={showPlayerModal} setShowModal={() => setShowPlayerModal(false)} chosenPlayer={chosenPlayer} />
        </div>
    );
};

import { Col, Form } from "react-bootstrap";
import TeamModel from "../Models/TeamModel";

export const FormationPlayer: React.FC<{
    playerId: number | undefined;
    playerLabel: string;
    validatePlayerId: any;
    setPlayer: any;
    team: TeamModel;
}> = (props) => {

    return (
        <Col md={3} mb={3}
            style={{ width: 200 }}>
            <Form.Label>{props.playerLabel}</Form.Label>
            <Form.Select
                value={props.playerId}
                onChange={(e) => {
                    props.validatePlayerId(e.target.value);
                    props.setPlayer(parseInt(e.target.value));
                }}>
                <option value="0">{props.playerLabel}</option>
                {props.team.players.map((p) => {
                    return (
                        <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                    );
                })}
            </Form.Select>
        </Col >
    );
};

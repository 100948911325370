import secureLocalStorage from "react-secure-storage";
import { TLError } from "./TLError";

export const ApiCall = async (
    action: string,
    method: string,
    accessToken?: string,
    body?: object
): Promise<any> => {

    let requestOptions = {};

    if (accessToken) {
        requestOptions = {
            method: method,
            body: JSON.stringify(body),
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
    } else {
        requestOptions = {
            method: method,
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        };
    }

    const response = await fetch(process.env.REACT_APP_API_URL + action, requestOptions);

    let responseJson = await response.json();

    if (responseJson.errorCode) {
        if (responseJson.errorCode === "000013") {
            localStorage.removeItem("token");
            secureLocalStorage.removeItem("userContext");
            window.location.replace(process.env.REACT_APP_SITE_URL + "/login");
        }
        throw new TLError(responseJson.errorDisplayMessage, responseJson.errorCode);
    }

    return responseJson;
};

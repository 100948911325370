export const ENDPOINTS = {

    deleteBook: {
        action: "admin/books",
        method: "DELETE",
    },

    addNewBook: {
        action: "admin/books",
        method: "POST",
    },

    updateBookQuantity: {
        action: "admin/books/quantity",
        method: "PUT",
    },

    getBooks: {
        action: "books",
        method: "GET",
    },

    findBookByTitleContainingOrCategory: {
        action: "books/search/findByTitleContainingOrCategory",
        method: "GET",
    },

    checkoutBook: {
        action: "books/checkoutBook",
        method: "PUT",
    },

    getUserCurrentLoans: {
        action: "books/currentLoans",
        method: "GET",
    },

    getCurrentLoansCountByUser: {
        action: "books/currentLoansCount",
        method: "GET",
    },

    getBookWithoutCopies: {
        action: "books/getBookWithoutCopies",
        method: "POST",
    },

    getBookCheckoutInformation: {
        action: "books/getBookCheckoutInformation",
        method: "POST",
    },

    isBookCheckedOutByUser: {
        action: "books/isBookCheckedOutByUser",
        method: "POST",
    },

    renewLoan: {
        action: "books/renewLoan",
        method: "PUT",
    },

    returnBook: {
        action: "books/returnBook",
        method: "PUT",
    },

    getUserHistory: {
        action: "histories/search/findBooksByUserEmail",
        method: "GET",
    },

    getLeague: {
        action: "leagues/getLeague",
        method: "POST",
    },

    getLeagues: {
        action: "leagues/getLeagues",
        method: "POST",
    },

    getUsersTeams: {
        action: "teams/getUsersTeams",
        method: "POST",
    },

    assignTeam: {
        action: "teams/assignTeam",
        method: "POST",
    },

    validateCurrentTeam: {
        action: "teams/validateCurrentTeam",
        method: "POST",
    },

    getTeam: {
        action: "teams/getTeam",
        method: "POST",
    },

    getLeagueWithTeamId: {
        action: "leagues/getLeagueWithTeamId",
        method: "POST",
    },

    getUserMessages: {
        action: "messages/search/findByClosed",
        method: "GET",
    },

    getMessagesByUserEmail: {
        action: "messages/search/findByUserEmail",
        method: "GET",
    },

    submitNewQuestion: {
        action: "messages/messages",
        method: "POST",
    },

    submitResponseToQuestion: {
        action: "messages/messages",
        method: "PUT",
    },

    doesUserHaveBookReview: {
        action: "reviews/doesUserHaveBookReview",
        method: "GET",
    },

    getBookReviewsByBookId: {
        action: "reviews/search/findByBookId",
        method: "GET",
    },

    submitReview: {
        action: "reviews/reviews",
        method: "POST",
    },

    login: {
        action: "users/login",
        method: "POST",
    },

    register: {
        action: "users/register",
        method: "POST",
    },

    getUser: {
        action: "users/getUser",
        method: "POST",
    },

    updateUser: {
        action: "users/updateUser",
        method: "POST",
    },

    updateTactics: {
        action: "teams/updateTactic",
        method: "POST",
    },

    simulateMatch: {
        action: "matches/simulateMatch",
        method: "POST",
    },

    addRandomLeague: {
        action: "leagues/addRandomLeague",
        method: "POST",
    },

    getTeamMatches: {
        action: "matches/getTeamMatches",
        method: "POST",
    },

    startTraining: {
        action: "training/startTraining",
        method: "POST",
    },

    endTraining: {
        action: "training/endTraining",
        method: "POST",
    },

    putPlayerOnTransferList: {
        action: "transfer/putPlayerOnTransferList",
        method: "POST",
    },

    getTransferListPlayers: {
        action: "transfer/getTransferListPlayers",
        method: "POST",
    },

    buyPlayer: {
        action: "transfer/buyPlayer",
        method: "POST",
    },

    getLeagueFixture: {
        action: "fixtures/getLeagueFixture",
        method: "POST",
    },

    getMatch: {
        action: "matches/getMatch",
        method: "POST",
    },

    startStadiumUpgrade: {
        action: "stadium/startStadiumUpgrade",
        method: "POST",
    },

    endStadiumUpgrade: {
        action: "stadium/endStadiumUpgrade",
        method: "POST",
    },

    getHomepage: {
        action: "homepage/getHomepage",
        method: "POST",
    },

    getTransferHistories: {
        action: "transferHistory/getTransferHistories",
        method: "POST",
    },

    removePlayerFromTransferList: {
        action: "transfer/removePlayerFromTransferList",
        method: "POST",
    },

    startTreatment: {
        action: "doctor/startTreatment",
        method: "POST",
    },

    endTreatment: {
        action: "doctor/endTreatment",
        method: "POST",
    },

    startHearing: {
        action: "lawyer/startHearing",
        method: "POST",
    },

    endHearing: {
        action: "lawyer/endHearing",
        method: "POST",
    },

    startResearch: {
        action: "dataAnalyst/startResearch",
        method: "POST",
    },

    endResearch: {
        action: "dataAnalyst/endResearch",
        method: "POST",
    },

    startSearch: {
        action: "scout/startSearch",
        method: "POST",
    },

    endSearch: {
        action: "scout/endSearch",
        method: "POST",
    },

    buyScoutedPlayer: {
        action: "scout/buyScoutedPlayer",
        method: "POST",
    },

};

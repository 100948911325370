import { useState } from "react";
import { Button, Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import Countdown from "react-countdown";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { LoadingModal } from "./LoadingModal";
import TeamModel from "../Models/TeamModel";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const Stadium: React.FC<{
    team: TeamModel;
}> = (props) => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [displayWarning, setDisplayWarning] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [ticketLevel, setTicketLevel] = useState<number | undefined>(props.team.stadium.ticketLevel);
    const [ticketLevelUpgradeEndDate, setTicketLevelUpgradeEndDate] = useState(props.team.stadium.ticketLevelUpgradeEndDate ? new Date(props.team.stadium.ticketLevelUpgradeEndDate) : "");

    const [trainingLevel, setTrainingLevel] = useState<number | undefined>(props.team.stadium.trainingLevel);
    const [trainingLevelUpgradeEndDate, setTrainingLevelUpgradeEndDate] = useState(props.team.stadium.trainingLevelUpgradeEndDate ? new Date(props.team.stadium.trainingLevelUpgradeEndDate) : "");

    const [homeBonusLevel, setHomeBonusLevel] = useState<number | undefined>(props.team.stadium.homeBonusLevel);
    const [homeBonusLevelUpgradeEndDate, setHomeBonusLevelUpgradeEndDate] = useState(props.team.stadium.homeBonusLevelUpgradeEndDate ? new Date(props.team.stadium.homeBonusLevelUpgradeEndDate) : "");

    const [addedUpgradePercentage, setAddedUpgradePercentage] = useState();

    const [displaySuccessMessage, setDisplaySuccessMessage] = useState("Success");

    async function startStadiumUpgrade(facilityId: number | undefined) {
        setDisplayWarning("");
        setIsLoading(true);
        try {
            const responseJson = await ApiCall(
                ENDPOINTS.startStadiumUpgrade.action,
                ENDPOINTS.startStadiumUpgrade.method,
                userAuth.token,
                {
                    facilityId: facilityId,
                    teamId: props.team.id
                }
            );

            setTicketLevel(responseJson.stadiumDTO.ticketLevel ? responseJson.stadiumDTO.ticketLevel : 0);
            setTrainingLevel(responseJson.stadiumDTO.trainingLevel ? responseJson.stadiumDTO.trainingLevel : 0);
            setHomeBonusLevel(responseJson.stadiumDTO.homeBonusLevel ? responseJson.stadiumDTO.homeBonusLevel : 0);
            setTicketLevelUpgradeEndDate(responseJson.stadiumDTO.ticketLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.ticketLevelUpgradeEndDate) : "");
            setTrainingLevelUpgradeEndDate(responseJson.stadiumDTO.trainingLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.trainingLevelUpgradeEndDate) : "");
            setHomeBonusLevelUpgradeEndDate(responseJson.stadiumDTO.homeBonusLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.homeBonusLevelUpgradeEndDate) : "");

            setDisplaySuccessMessage(t('common.success'));
            setIsLoading(false);
            setDisplaySuccess(true);
            setDisplayWarning("");
        } catch (error: any) {
            setIsLoading(false);
            setDisplaySuccess(false);
            setDisplayWarning(error.message);
        }
    };

    async function endStadiumUpgrade(facilityId: number | undefined) {

        setDisplayWarning("");
        setIsLoading(true);
        try {
            const responseJson = await ApiCall(
                ENDPOINTS.endStadiumUpgrade.action,
                ENDPOINTS.endStadiumUpgrade.method,
                userAuth.token,
                {
                    facilityId: facilityId,
                    teamId: props.team.id
                }
            );

            setTicketLevel(responseJson.stadiumDTO.ticketLevel ? responseJson.stadiumDTO.ticketLevel : 0);
            setTrainingLevel(responseJson.stadiumDTO.trainingLevel ? responseJson.stadiumDTO.trainingLevel : 0);
            setHomeBonusLevel(responseJson.stadiumDTO.homeBonusLevel ? responseJson.stadiumDTO.homeBonusLevel : 0);
            setTicketLevelUpgradeEndDate(responseJson.stadiumDTO.ticketLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.ticketLevelUpgradeEndDate) : "");
            setTrainingLevelUpgradeEndDate(responseJson.stadiumDTO.trainingLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.trainingLevelUpgradeEndDate) : "");
            setHomeBonusLevelUpgradeEndDate(responseJson.stadiumDTO.homeBonusLevelUpgradeEndDate ? new Date(responseJson.stadiumDTO.homeBonusLevelUpgradeEndDate) : "");

            setAddedUpgradePercentage(responseJson.addedUpgradePercentage);

            setDisplaySuccessMessage(t('stadium.success') + facilityId + t('stadium.isUpgradedBy') + addedUpgradePercentage + t('stadium.buildingPoints'));

            setIsLoading(false);
            setDisplaySuccess(true);
            setDisplayWarning("");
        } catch (error: any) {
            setIsLoading(false);
            setDisplaySuccess(false);
            setDisplayWarning(error.message);
        }
    };

    return (
        <Container className="mt-2 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {displaySuccessMessage}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Card className="mb-3">
                <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
                <Card.Header>{t('stadium.title')}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{t('stadium.stadiumCapacity')}<br />{ticketLevel + "/10"}</Form.Label><br />
                                <Form.Label>{ticketLevelUpgradeEndDate ? <Countdown date={moment(ticketLevelUpgradeEndDate).toDate()} /> : t('stadium.upgradeStadiumCapacity')}</Form.Label>
                                <Form.Range
                                    min={0}
                                    max={10}
                                    value={ticketLevel}
                                    disabled />
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        ticketLevelUpgradeEndDate ? endStadiumUpgrade(0) : startStadiumUpgrade(0);
                                    }}                                >
                                    {ticketLevel && ticketLevelUpgradeEndDate ? t('stadium.completeUpgrade') : t('stadium.upgrade')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{t('stadium.stadiumTrainingFacilities')}<br />{trainingLevel + "/10"}</Form.Label><br />
                                <Form.Label>{trainingLevelUpgradeEndDate ? <Countdown date={moment(trainingLevelUpgradeEndDate).toDate()} /> : t('stadium.upgradeTrainingFacilities')}</Form.Label>
                                <Form.Range
                                    min={0}
                                    max={10}
                                    value={trainingLevel}
                                    disabled />
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        trainingLevelUpgradeEndDate ? endStadiumUpgrade(1) : startStadiumUpgrade(1);
                                    }}                                >
                                    {trainingLevel && trainingLevelUpgradeEndDate ? t('stadium.completeUpgrade') : t('stadium.upgrade')}
                                </Button>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{t('stadium.stadiumHomeBonusFacility')}<br />{homeBonusLevel + "/10"}</Form.Label><br />
                                <Form.Label>{homeBonusLevelUpgradeEndDate ? <div>{t('stadium.stadiumHomeBonusFacility') + homeBonusLevel + "/10"}<br /><Countdown date={moment(homeBonusLevelUpgradeEndDate).toDate()} /></div> : t('stadium.upgradeHomeBonusFacility')}</Form.Label>
                                <Form.Range
                                    min={0}
                                    max={10}
                                    value={homeBonusLevel}
                                    disabled />
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => {
                                        homeBonusLevelUpgradeEndDate ? endStadiumUpgrade(2) : startStadiumUpgrade(2);
                                    }}                                >
                                    {homeBonusLevel && homeBonusLevelUpgradeEndDate ? t('stadium.completeUpgrade') : t('stadium.upgrade')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container >
    );
};

export const UrlCreator = (baseUrl: string, pathVariables: any[]) => {

    baseUrl += "?";

    pathVariables.forEach((pv) => {
        if (pv && pv.name) {
            baseUrl += pv.name + "=" + pv.value + "&";
        }
    });

    return baseUrl.substring(0, baseUrl.length - 1);
};

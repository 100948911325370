import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import { Footer } from "./Layouts/Footer";
import { Header } from "./Layouts/Header";
import { AdminPage } from "./Pages/AdminPage";
import { Fixture } from "./Pages/Fixture";
import { HomePage } from "./Pages/HomePage";
import { League } from "./Pages/League";
import { Leagues } from "./Pages/Leagues";
import { LeagueStandings } from "./Pages/LeagueStandings";
import Login from "./Pages/Login";
import { Matches } from "./Pages/Matches";
import { MessagesPage } from "./Pages/MessagesPage";
import { MyTeams } from "./Pages/MyTeams";
import RegisterPage from "./Pages/RegisterPage";
import Settings from "./Pages/Settings";
import { Team } from "./Pages/Team";
import { TransferList } from "./Pages/TransferList";
import { useAuth } from "./Utility/useAuth";
import { Doctor } from "./Pages/Doctor";
import { Lawyer } from "./Pages/Lawyer";
import { DataAnalyst } from "./Pages/DataAnalyst";
import { Scout } from "./Pages/Scout";

export const App = () => {

    const userAuth = useAuth();

    return (
        <div className="d-flex flex-column min-vh-100 vh-100">
            <Header />
            <div className="flex-grow-1">
                <Switch>
                    <Route path="/" exact>
                        <Redirect to="/home" />
                    </Route>

                    <Route path="/home" exact>
                        <HomePage />
                    </Route>

                    <Route path="/login" exact>
                        <Login />
                    </Route>

                    <Route path="/register" exact>
                        <RegisterPage />
                    </Route>

                    <Route path="/leagues/:leagueId" exact>
                        <League />
                    </Route>

                    <Route path="/leagues" exact>
                        <Leagues />
                    </Route>

                    <Route path="/teams/:teamId" exact>
                        <Team />
                    </Route>

                    {userAuth.isAuthenticated ? (
                        <Route path="/fixture" exact>
                            <Fixture />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/transferList" exact>
                            <TransferList />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/matches" exact>
                            <Matches />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/leagueTable" exact>
                            <LeagueStandings />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/myTeams/:teamId" exact>
                            <Team />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/myTeams" exact>
                            <MyTeams />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/doctor" exact>
                            <Doctor />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/lawyer" exact>
                            <Lawyer />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/dataAnalyst" exact>
                            <DataAnalyst />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/scout" exact>
                            <Scout />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/settings">
                            <Settings />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated ? (
                        <Route path="/messages">
                            <MessagesPage />
                        </Route>
                    ) : (
                        <></>
                    )}

                    {userAuth.isAuthenticated && userAuth.roles.filter((r: { value: string; }) => r.value === "admin").length > 0 ? (
                        <Route path="/admin">
                            <AdminPage />
                        </Route>
                    ) : (
                        <></>
                    )}

                </Switch>
            </div>
            <Footer />
        </div>
    );
};

export default App;

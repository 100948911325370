import { Col } from "react-bootstrap";
import RefereeModel from "../Models/RefereeModel";

export const RefereeCol: React.FC<{
    referee: RefereeModel;
}> = (props) => {

    function getCrueltyColor(cruelty: number) {
        let color = "";
        if (cruelty) {
            if (cruelty >= 80) {
                color = "red";
            } else if (cruelty >= 60) {
                color = "orange";
            } else if (cruelty >= 40) {
                color = "goldenrod";
            } else if (cruelty >= 20) {
                color = "blue";
            } else if (cruelty >= 0) {
                color = "green";
            }
        }
        return color;
    }

    return (
        <Col style={{ color: getCrueltyColor(props.referee.cruelty) }}>
            {props.referee.name + " " + props.referee.cruelty}
        </Col>
    );
};

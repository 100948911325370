import Pagination from "react-bootstrap/Pagination";
import { useTranslation } from "react-i18next";

export const TLPagination: React.FC<{
    currentPage: number;
    totalPages: number;
    paginate: any;
}> = (props) => {

    const { t } = useTranslation();

    const pageNumbers = [];

    if (props.currentPage === 1) {
        pageNumbers.push(props.currentPage);
        if (props.totalPages >= props.currentPage + 1) {
            pageNumbers.push(props.currentPage + 1);
        }
        if (props.totalPages >= props.currentPage + 2) {
            pageNumbers.push(props.currentPage + 2);
        }
    } else if (props.currentPage > 1) {
        if (props.currentPage >= 3) {
            pageNumbers.push(props.currentPage - 2);
            pageNumbers.push(props.currentPage - 1);
        } else {
            pageNumbers.push(props.currentPage - 1);
        }

        pageNumbers.push(props.currentPage);

        if (props.totalPages >= props.currentPage + 1) {
            pageNumbers.push(props.currentPage + 1);
        }
        if (props.totalPages >= props.currentPage + 2) {
            pageNumbers.push(props.currentPage + 2);
        }
    }

    return (
        <nav aria-label="...">
            <Pagination>
                <Pagination.Item onClick={() => props.paginate(1)}>
                    {(t('tlPagination.firstPage'))}
                </Pagination.Item>
                {pageNumbers.map((number) => (
                    <Pagination.Item
                        key={number}
                        onClick={() => props.paginate(number)}
                        active={props.currentPage === number}
                    >
                        {number}
                    </Pagination.Item>
                ))}
                <Pagination.Item onClick={() => props.paginate(props.totalPages)}>
                    {(t('tlPagination.lastPage'))}
                </Pagination.Item>
            </Pagination>
        </nav>
    );
};

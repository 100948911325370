export const TACTIC_CONSTANTS = {

    FORMATION_TYPE: [
        {
            id: "0",
            code: "FOUR_THREE_THREE",
            name: "4-3-3",
            defenders: 4,
            midfielders: 3,
            forwards: 3
        },
        {
            id: "1",
            code: "FOUR_FIVE_ONE",
            name: "4-5-1",
            defenders: 4,
            midfielders: 5,
            forwards: 1
        },
        {
            id: "2",
            code: "FOUR_TWO_THREE_ONE",
            name: "4-5-1",
            defenders: 4,
            midfielders: 5,
            forwards: 1
        },
        {
            id: "3",
            code: "FOUR_FOUR_TWO",
            name: "4-4-2",
            defenders: 4,
            midfielders: 4,
            forwards: 2
        },
        {
            id: "4",
            code: "THREE_TWO_FIVE",
            name: "3-2-5",
            defenders: 3,
            midfielders: 2,
            forwards: 5
        },
        {
            id: "5",
            code: "THREE_TWO_THREE_TWO",
            name: "3-2-3-2",
            defenders: 3,
            midfielders: 5,
            forwards: 2
        },
        {
            id: "6",
            code: "THREE_THREE_FOUR",
            name: "3-3-4",
            defenders: 3,
            midfielders: 3,
            forwards: 4
        },
        {
            id: "7",
            code: "THREE_TWO_FIVE",
            name: "3-2-5",
            defenders: 3,
            midfielders: 2,
            forwards: 5
        },
        {
            id: "8",
            code: "THREE_FOUR_THREE",
            name: "3-4-3",
            defenders: 3,
            midfielders: 4,
            forwards: 3
        },
        {
            id: "9",
            code: "THREE_THREE_TWO_TWO",
            name: "3-3-2-2",
            defenders: 3,
            midfielders: 5,
            forwards: 2
        },
        {
            id: "10",
            code: "THREE_FIVE_TWO",
            name: "3-5-2",
            defenders: 3,
            midfielders: 5,
            forwards: 2
        },
        {
            id: "11",
            code: "FOUR_TWO_FOUR",
            name: "4-2-4",
            defenders: 4,
            midfielders: 2,
            forwards: 4
        },
        {
            id: "12",
            code: "FIVE_TWO_THREE",
            name: "5-2-3",
            defenders: 5,
            midfielders: 2,
            forwards: 3
        },
        {
            id: "13",
            code: "FIVE_THREE_TWO",
            name: "5-3-2",
            defenders: 5,
            midfielders: 3,
            forwards: 2
        },
        {
            id: "13",
            code: "FIVE_THREE_ONE_ONE",
            name: "5-3-1-1",
            defenders: 5,
            midfielders: 3,
            forwards: 2
        },
        {
            id: "13",
            code: "FIVE_FOUR_ONE",
            name: "5-4-1",
            defenders: 5,
            midfielders: 4,
            forwards: 1
        },
        {
            id: "13",
            code: "SIX_THREE_ONE",
            name: "6-3-1",
            defenders: 6,
            midfielders: 3,
            forwards: 1
        },
    ],

    FORWARD_LINE_TACTIC: [
        {
            id: "0",
            code: "ATTACK_ONLY",
            name: "Attack Only",
        },
        {
            id: "1",
            code: "HELP_MIDDLE",
            name: "Help Middle",
        },
        {
            id: "2",
            code: "DROP_DOWN_TO_DEFENSE",
            name: "Drop Down to Defense",
        },
    ],
    MIDFILEDER_LINE_TACTIC: [
        {
            id: "0",
            code: "PUSH_FORWARD",
            name: "Push Forward",
        },
        {
            id: "1",
            code: "STAY_IN_POSITION",
            name: "Stay in Position",
        },
        {
            id: "2",
            code: "HELP_DEFENSE",
            name: "Help Defense",
        },
    ],
    DEFENDER_LINE_TACTIC: [
        {
            id: "0",
            code: "PUSH_MIDDLE",
            name: "Push Middle",
        },
        {
            id: "1",
            code: "STAY_IN_POSITION",
            name: "Stay in Position",
        },
        {
            id: "2",
            code: "DEFEND_DEEP",
            name: "Defend Deep",
        },
    ],
    GAMEPLAY_TYPE: [
        {
            id: "0",
            code: "LONG_BALL",
            name: "Long Ball",
        },
        {
            id: "1",
            code: "PASSING_GAME",
            name: "Passing Game",
        },
        {
            id: "2",
            code: "WING_PLAY",
            name: "Wing Play",
        },
        {
            id: "3",
            code: "COUNTER_ATTACK",
            name: "Counter Attack",
        },
        {
            id: "4",
            code: "SHOOT_ON_SIGHT",
            name: "Shoot on Sight",
        },
    ],
    MARKING_TYPE: [
        {
            id: "0",
            code: "ZONAL_MARKING",
            name: "Zonal Marking",
        },
        {
            id: "1",
            code: "MAN_MARKING",
            name: "Man Marking",
        },
    ],
    TACKLING_TYPE: [
        {
            id: "0",
            code: "GENTLE",
            name: "Gentle",
        },
        {
            id: "1",
            code: "CASUAL",
            name: "Casual",
        },
        {
            id: "2",
            code: "NORMAL",
            name: "Normal",
        },
        {
            id: "3",
            code: "AGGRESSIVE",
            name: "Aggressive",
        },
        {
            id: "4",
            code: "HARDCORE",
            name: "Hardcore",
        },
    ]
};

import { ListGroup, Badge } from "react-bootstrap";

export const LabelDataPair: React.FC<{
    label: string;
    value: any;
}> = (props) => {
    return (
        <ListGroup.Item className="d-flex justify-content-between align-items-start">
            {props.label}
            <Badge bg="primary" pill>
                {props.value}
            </Badge>
        </ListGroup.Item>
    );
};

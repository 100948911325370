import { useEffect, useState } from "react";
import { Container, Alert, Card, Form, Row, Col, Button, } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { LoadingModal } from "../Components/LoadingModal";
import { useTranslation } from "react-i18next";

const Login = () => {

    const { t } = useTranslation();

    const history = useHistory();

    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        document.title = t('title.login');
    }, [t]);

    async function login() {
        setIsLoading(true);
        try {
            const crypto = require("crypto-js");
            const hashedPassword = crypto.MD5(password).toString();

            const responseJson = await ApiCall(
                ENDPOINTS.login.action,
                ENDPOINTS.login.method,
                "",
                { username: username, password: hashedPassword }
            );
            localStorage.setItem("token", responseJson.token);
            setIsLoading(false);
            setDisplayWarning(false);
            setDisplaySuccess(true);
            history.push("/myTeams");
            window.location.reload();
        } catch (error: any) {
            setIsLoading(false);
            setDisplayWarning(error.message);
            setDisplaySuccess(false);
        }
    }

    return (
        <Container className="mt-5 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {t('login.successLoadingHomePage')}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Card>
                <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
                <Card.Header>{t('login.title')}</Card.Header>
                <Card.Body>
                    <Form>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{t('login.username')}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder={t('login.username')}
                                    required
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{t('login.password')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={t('login.password')}
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => login()}
                                >
                                    {t('login.login')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Login;

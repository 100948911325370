import { Card, Col, Container, Row } from "react-bootstrap";
import FixtureModel from "../Models/FixtureModel";
import { useEffect, useState } from "react";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import Countdown from "react-countdown";
import moment from "moment";
import LeagueModel from "../Models/LeagueModel";
import { ErrorModal } from "../Components/ErrorModal";
import { LeagueTable } from "../Components/LeaugeTable";
import { LoadingModal } from "../Components/LoadingModal";
import TeamModel from "../Models/TeamModel";
import { Wallet } from "../Components/Wallet";
import { useTranslation } from 'react-i18next';
import { RefereeCol } from "../Components/RefereeCol";
import { TLError } from "../Utility/TLError";

export const HomePage = () => {


    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [fixture, setFixture] = useState<FixtureModel>();
    const [league, setLeague] = useState<LeagueModel>();
    const [team, setTeam] = useState<TeamModel | undefined>(undefined);
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        document.title = fixture?.match?.date ? getTimeRemainingString(moment.utc(fixture?.match?.date).local().toString()) + " - " + t('title.homePage') : t('title.homePage');
    }, [t, fixture?.match.date, time]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const getTimeRemainingString = (e: string) => {

        const total = Date.parse(e) - Date.now();
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        const days = Math.floor((total / 1000 / 60 / 60 / 24) % 30);

        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            return t('homePage.matchInPlay');
        }

        const hoursString = hours > 9 ? hours.toString() : "0" + hours;
        const minutesString = minutes > 9 ? minutes.toString() : "0" + minutes;
        const secondsString = seconds > 9 ? seconds.toString() : "0" + seconds;

        return days + ":" + hoursString + ":" + minutesString + ":" + secondsString;
    };

    useEffect(() => {

        if (userContext.currentTeamId !== -1) {

            const getTeam = async () => {

                const responseJson = await ApiCall(
                    ENDPOINTS.getTeam.action,
                    ENDPOINTS.getTeam.method,
                    userAuth.token,
                    { teamId: userContext.currentTeamId }
                );
                setTeam(responseJson.team);
            };
            getTeam().catch((error: TLError) => {
                setIsLoading(false);
                setError(error);
            });
        }

    }, [userAuth.token, userContext.currentTeamId]);

    useEffect(() => {

        const getHomepage = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getHomepage.action,
                ENDPOINTS.getHomepage.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            responseJson.league.teams.sort((a: TeamModel, b: TeamModel) => a.point < b.point ? 1 : (a.point > b.point ? -1 : 0));

            setFixture(responseJson.fixture);
            setLeague(responseJson.league);
            setError(new TLError("", ""));

        };
        getHomepage().catch((error: TLError) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);


    return (
        <div
            className="bg-dark header" >
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <br />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <Container
                fluid
                className="py-5 text-white d-flex justify-content-center align-items-center place-items-center"
            >
                <div className="text-center">
                    {fixture &&
                        <h1 className="display-5 fw-bold text-center">{t('homePage.day')} {fixture?.day}</h1>
                    }
                    {fixture?.match &&
                        <Row>
                            <Col >
                                <Card className="mt-2 shadow p-5 bg-dark rounded">
                                    <Card.Title>
                                        {fixture?.homeTeam?.name}
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 ">
                                        {fixture?.homeTeamRating}
                                        <br />
                                        {fixture?.homeTeam?.user?.username || <br />}
                                    </Card.Subtitle>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="mt-2 shadow p-5 bg-dark rounded ">
                                    <Card.Title>
                                        <Countdown date={moment(fixture?.match?.date).toDate()} >
                                            <>{t('homePage.matchInPlay')}</>
                                        </Countdown>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 ">
                                        <RefereeCol referee={fixture.match.referee} />
                                        <br />
                                    </Card.Subtitle>
                                </Card>
                            </Col>
                            <Col >
                                <Card className="mt-2 shadow p-5 bg-dark rounded">
                                    <Card.Title>
                                        {fixture?.awayTeam?.name}
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 ">
                                        {fixture?.awayTeamRating}
                                        <br />
                                        {fixture?.awayTeam?.user?.username || <br />}
                                    </Card.Subtitle>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <br />
                    <LeagueTable setShowMatchModal={false} showMatchModal={false} match={null} simulateMatch={undefined} league={league} assignTeam={undefined} assignTeamAllowed={false} askForNewLeagueSection={false} playable={false} />
                </div>
            </Container>
        </div>
    );
};

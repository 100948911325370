import { useState, useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { TransferListTable } from "../Components/TransferListTable";
import { Wallet } from "../Components/Wallet";
import PlayerModel from "../Models/PlayerModel";
import TeamModel from "../Models/TeamModel";
import { TransferHistoryTable } from "../Components/TransferHistoryTable";
import TransferHistoryModel from "../Models/TransferHistoryModel";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const TransferList = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [isPlayerBought, setIsPlayerBought] = useState(false);

    const [team, setTeam] = useState<TeamModel | undefined>(undefined);
    const [players, setPlayers] = useState<PlayerModel[]>([]);
    const [transferHistories, setTransferHistories] = useState<TransferHistoryModel[]>([]);

    useEffect(() => {
        document.title = t('title.transferList');
    }, [t]);

    useEffect(() => {

        const getTransferHistories = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTransferHistories.action,
                ENDPOINTS.getTransferHistories.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTransferHistories(responseJson.transferHistoryDTOList);

            setError(new TLError("", ""));
        };
        getTransferHistories().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);

            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    useEffect(() => {

        const getTransferListPlayers = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTransferListPlayers.action,
                ENDPOINTS.getTransferListPlayers.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );

            setPlayers(responseJson.players);

            setError(new TLError("", ""));
            setIsLoading(false);
        };
        getTransferListPlayers().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    async function buyPlayer(playerId: number) {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.buyPlayer.action,
                ENDPOINTS.buyPlayer.method,
                userAuth.token,
                {
                    teamId: userContext.currentTeamId,
                    playerId: playerId,
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function removePlayerFromTransferList(playerId: number) {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.removePlayerFromTransferList.action,
                ENDPOINTS.removePlayerFromTransferList.method,
                userAuth.token,
                {
                    playerId: playerId
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br />
            {
                <Tabs
                    id="nav-tab"
                    role="tablist"
                >
                    <Tab eventKey="transferList" title={(t('transferList.transferList'))}>
                        <TransferListTable team={team} players={players} buyPlayer={buyPlayer} removePlayerFromTransferList={removePlayerFromTransferList} />
                    </Tab>
                    <Tab eventKey="transferHistory" title={(t('transferList.transferHistory'))}>
                        <TransferHistoryTable transferHistories={transferHistories} />
                    </Tab>
                </Tabs>
            }
        </Container>
    );
};

import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { Wallet } from "../Components/Wallet";
import TeamModel from "../Models/TeamModel";
import Countdown from "react-countdown";
import { MessageModal } from "../Components/MessageModal";
import ReserachedTeamResultModel from "../Models/ReserachedTeamResultModel";
import { LabelDataPair } from "../Components/LabelDataPair";
import { TACTIC_CONSTANTS } from "../Utility/FormationConstants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TLError } from "../Utility/TLError";

export const DataAnalyst = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));
    const [successMessage, setSuccessMessage] = useState("");

    const [isPlayerBought, setIsPlayerBought] = useState(false);

    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    const [chosenTeam, setChosenTeam] = useState<TeamModel>();

    useEffect(() => {
        document.title = t('title.dataAnalyst');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    async function startResearch(researchedTeamId: number) {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.startResearch.action,
                ENDPOINTS.startResearch.method,
                userAuth.token,
                {
                    researcherTeamId: userContext.currentTeamId,
                    researchedTeamId: researchedTeamId
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function endResearch() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.endResearch.action,
                ENDPOINTS.endResearch.method,
                userAuth.token,
                {
                    researcherTeamId: userContext.currentTeamId
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setIsLoading(false);
            setError(new TLError("", ""));
            setSuccessMessage(team?.dataAnalyst.currentlyResearchedTeam + t('dataAnalyst.isSuccessfullyResearched'));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            setSuccessMessage("");
        }
    };

    function getDataAnalystButtonLabel(teamResearchResult: ReserachedTeamResultModel) {
        let buttonLabel = "";
        if (teamResearchResult.isResearched) {
            buttonLabel = t('dataAnalyst.display');
        }
        else if (team?.dataAnalyst.currentlyResearchedTeam && team.dataAnalyst.researchEndDate && team.dataAnalyst.currentlyResearchedTeam.id === teamResearchResult.team.id) {
            buttonLabel = t('dataAnalyst.complete');
        } else {
            buttonLabel = t('dataAnalyst.research');
        }
        return buttonLabel;
    }

    function getDataAnalystButtonAction(teamResearchResult: ReserachedTeamResultModel) {
        if (teamResearchResult.isResearched) {
            setChosenTeam(teamResearchResult.team);
        }
        else if (team?.dataAnalyst.currentlyResearchedTeam && team.dataAnalyst.researchEndDate && team.dataAnalyst.currentlyResearchedTeam.id === teamResearchResult.team.id) {
            endResearch();
        } else {
            startResearch(teamResearchResult.team.id);
        }
    }


    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            <MessageModal title={t('common.success')} message={successMessage} isShown={successMessage !== ""} close={() => setSuccessMessage("")} />

            <Row xs={1} md={3} className="g-4">
                {
                    team && team.dataAnalyst && team.dataAnalyst.teamResearchResults ? team.dataAnalyst.teamResearchResults.filter(trr => trr.team.id !== userContext.currentTeamId).map(trr => {
                        return (
                            <Col key={trr.team.id}>
                                <Card className="mb-3" >
                                    <Card.Header>{trr.team.name}<img style={{ float: "right" }} src={require("./../Assets/Flags/" + trr.team.code + ".jpg")} width="30" height="20" alt="Default" /></Card.Header>
                                    <Card.Body>
                                        <Form id="form" noValidate>
                                            <Row className="justify-content-md-center text-center mb-3">
                                                <Col md={5} mb={5} >
                                                    {team?.dataAnalyst?.currentlyResearchedTeam && team.dataAnalyst.currentlyResearchedTeam.id === trr.team.id && team.dataAnalyst?.researchEndDate ? <Countdown date={moment(team.dataAnalyst?.researchEndDate).toDate()} /> : ""}
                                                    <br />
                                                    <Button
                                                        variant="primary"
                                                        className="mt-3"
                                                        onClick={() => {
                                                            getDataAnalystButtonAction(trr);
                                                        }}
                                                    >
                                                        {getDataAnalystButtonLabel(trr)}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card >
                            </Col>
                        );
                    })
                        : ""
                }
            </Row>
            <Modal show={chosenTeam !== undefined} onHide={() => { setChosenTeam(undefined); }}>
                <Modal.Header closeButton>
                    <Modal.Title>{chosenTeam?.name + " "}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <LabelDataPair label={t('dataAnalyst.stadiumLevel')} value={chosenTeam?.stadium.homeBonusLevel} />
                    </ListGroup>
                    <ListGroup>
                        <LabelDataPair label={t('dataAnalyst.formation')} value={TACTIC_CONSTANTS.FORMATION_TYPE[chosenTeam?.tactic?.formationId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.gameplay')} value={TACTIC_CONSTANTS.GAMEPLAY_TYPE[chosenTeam?.tactic?.gameplayTypeId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.marking')} value={TACTIC_CONSTANTS.MARKING_TYPE[chosenTeam?.tactic?.markingId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.tackling')} value={TACTIC_CONSTANTS.TACKLING_TYPE[chosenTeam?.tactic?.tacklingId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.forwardLineTactic')} value={TACTIC_CONSTANTS.FORWARD_LINE_TACTIC[chosenTeam?.tactic?.forwardLineTacticId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.midfielderLineTactic')} value={TACTIC_CONSTANTS.MIDFILEDER_LINE_TACTIC[chosenTeam?.tactic?.midfielderLineTacticId || 0].name} />
                        <LabelDataPair label={t('dataAnalyst.defenderLineTactic')} value={TACTIC_CONSTANTS.DEFENDER_LINE_TACTIC[chosenTeam?.tactic?.defenderLineTacticId || 0].name} />
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setChosenTeam(undefined); }}>
                        {t('common.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container >
    );
};

import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { Wallet } from "../Components/Wallet";
import TeamModel from "../Models/TeamModel";
import Countdown from "react-countdown";
import { MessageModal } from "../Components/MessageModal";
import ScoutedPlayerModel from "../Models/ScoutedPlayerModel";
import { COUNTRIES } from "../Utility/Countries";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PlayerDetailModal } from "../Components/PlayerDetailModal";
import PlayerModel from "../Models/PlayerModel";
import { TLError } from "../Utility/TLError";

export const Scout = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));
    const [successMessage, setSuccessMessage] = useState("");


    const [showPlayerModal, setShowPlayerModal] = useState(false);
    const [chosenPlayer, setChosenPlayer] = useState<PlayerModel | null>(null);

    const [isPlayerBought, setIsPlayerBought] = useState(false);

    const [team, setTeam] = useState<TeamModel | undefined>(undefined);
    const [ageCriteria, setAgeCriteria] = useState("");
    const [positionCriteria, setPositionCriteria] = useState("");
    const [specificPositionCriteria, setSpecificPositionCriteria] = useState("");
    const [nationalityCriteria, setNationalityCriteria] = useState("");
    const [ratingCriteria, setRatingCriteria] = useState("");

    useEffect(() => {
        document.title = t('title.scout');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);

            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);



    async function startSearch() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.startSearch.action,
                ENDPOINTS.startSearch.method,
                userAuth.token,
                {
                    teamId: userContext.currentTeamId,
                    ageCriteria: ageCriteria,
                    positionCriteria: positionCriteria,
                    specificPositionCriteria: specificPositionCriteria,
                    nationalityCriteria: nationalityCriteria,
                    ratingCriteria: ratingCriteria
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function endSearch() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.endSearch.action,
                ENDPOINTS.endSearch.method,
                userAuth.token,
                {
                    teamId: userContext.currentTeamId
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
            setSuccessMessage(t('scout.scoutReturnedWithPlayers'));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            setSuccessMessage("");
        }
    };

    async function buyScoutedPlayer(scoutedPlayer: ScoutedPlayerModel) {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.buyScoutedPlayer.action,
                ENDPOINTS.buyScoutedPlayer.method,
                userAuth.token,
                {
                    teamId: userContext.currentTeamId,
                    scoutedPlayerId: scoutedPlayer.id
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setIsLoading(false);
            setError(new TLError("", ""));
            setSuccessMessage(scoutedPlayer.player.name + " " + scoutedPlayer.player.surname + t('scout.isSuccessfullyBought'));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            setSuccessMessage("");
        }
    };

    return (
        <Container className="mt-3 mb-3">
            <PlayerDetailModal showModal={showPlayerModal} setShowModal={() => setShowPlayerModal(false)} chosenPlayer={chosenPlayer} />
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br />
            <MessageModal title={"Success!"} message={successMessage} isShown={successMessage !== ""} close={() => setSuccessMessage("")} />
            <div className="mb-5">
                {team?.scout.searchEndDate && new Date() > new Date(team?.scout.searchEndDate) ?
                    <Button
                        variant="primary"
                        onClick={() => endSearch()}
                    >
                        {t('scout.completeSearch')}
                    </Button> :
                    team?.scout.searchEndDate ?
                        <div>
                            {"Search End Countdown: "}
                            <Countdown date={moment(team?.scout.searchEndDate).toDate()} />
                        </div> : ""}
            </div>
            <Row xs={1} md={3} className="justify-content-md-center text-center g-4 mb-3">
                {
                    team && team.scout && team.scout.scoutedPlayers ? team.scout.scoutedPlayers.filter(sp => moment(sp.finalPurchasableDate).toDate() > moment().toDate()).map((sp, i) => {
                        return (
                            <Col key={i}>
                                <Card className="mb-3" >
                                    <Card.Header >
                                        <Button
                                            variant="secondary"
                                            className="mt-3"
                                            onClick={() => { setChosenPlayer(sp.player); setShowPlayerModal(true); }}
                                        >{sp.player.name + " " + sp.player.surname}</Button>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form id="form" noValidate>
                                            <Row className="justify-content-md-center text-center mb-3">
                                                <Col md={5} mb={5} >
                                                    {sp.player.position}
                                                    <br />
                                                    {sp.player.overallRating}
                                                    <br />
                                                    <Button
                                                        variant="primary"
                                                        className="mt-3"
                                                        onClick={() => {
                                                            buyScoutedPlayer(sp);
                                                        }}
                                                    >
                                                        {t('scout.buyPlayer')} ({sp.formattedPrice})
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card >
                            </Col>
                        );
                    })
                        : ""
                }
            </Row>
            <Card className="mb-3">
                <Card.Header>{t('scout.searchPlayers')}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate >
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{t('scout.age')}</Form.Label>
                                <Form.Select disabled={team?.scout.searchEndDate !== undefined} value={ageCriteria} onChange={(e) => setAgeCriteria(e.target.value)}>
                                    <option key={""}>Any</option>
                                    <option key={"1"} value="0">18-25</option>
                                    <option key={"2"} value="1">25-30</option>
                                    <option key={"3"} value="2">30-40</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{t('scout.nationality')}</Form.Label>
                                <Form.Select disabled={team?.scout.searchEndDate !== undefined} value={nationalityCriteria} onChange={(e) => setNationalityCriteria(e.target.value)}>
                                    <option key={""}>Any</option>
                                    {COUNTRIES.map(c => <option key={c.code} value={c.code}>{c.name}</option>)}
                                </Form.Select>
                            </Col>

                            <Col md={3} mb={3} >
                                <Form.Label>{t('scout.rating')}</Form.Label>
                                <Form.Select disabled={team?.scout.searchEndDate !== undefined} value={ratingCriteria} onChange={(e) => setRatingCriteria(e.target.value)}>
                                    <option key={""}>Any</option>
                                    <option key={"0"} value="0">0-65</option>
                                    <option key={"1"} value="1">65-80</option>
                                    <option key={"2"} value="2">80-100</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{t('scout.position')}</Form.Label>
                                <Form.Select disabled={team?.scout.searchEndDate !== undefined} value={positionCriteria} onChange={(e) => setPositionCriteria(e.target.value)}>
                                    <option key={""}>Any</option>
                                    <option key={"0"} value="0">Forward</option>
                                    <option key={"1"} value="1">Midfielder</option>
                                    <option key={"2"} value="2">Defender</option>
                                    <option key={"3"} value="3">Goalkeeper</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{t('scout.specificPosition')}</Form.Label>
                                <Form.Select disabled={team?.scout.searchEndDate !== undefined} value={specificPositionCriteria} onChange={(e) => setSpecificPositionCriteria(e.target.value)}>
                                    <option key={""}>Any</option>
                                    <option key={"0"} value="0">ST</option>
                                    <option key={"1"} value="1">LW</option>
                                    <option key={"2"} value="2">RW</option>
                                    <option key={"3"} value="3">CAM</option>
                                    <option key={"4"} value="4">CDM</option>
                                    <option key={"5"} value="5">CM</option>
                                    <option key={"6"} value="6">RM</option>
                                    <option key={"7"} value="7">LM</option>
                                    <option key={"8"} value="8">CB</option>
                                    <option key={"9"} value="9">LB</option>
                                    <option key={"10"} value="10">RB</option>
                                    <option key={"11"} value="11">GK</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Button
                                    disabled={team?.scout.searchEndDate !== undefined}
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => startSearch()}
                                >
                                    {team?.scout.searchEndDate ? t('scout.aSearchIsAlreadyInProgress') : t('scout.search')}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container >
    );
};

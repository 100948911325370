import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { UrlCreator } from "../Utility/UrlCreator";
import { useAuth } from "../Utility/useAuth";
import { SpinnerLoading } from "./SpinnerLoading";
import { TLPagination } from "./TLPagination";
import MessageModel from "../Models/MessageModel";
import { useTranslation } from "react-i18next";

export const Messages = () => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [httpError, setHttpError] = useState(null);

    const [messages, setMessages] = useState<MessageModel[]>([]);

    const [messagesPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {

        const fetchUserMessages = async () => {

            const action = UrlCreator(ENDPOINTS.getMessagesByUserEmail.action, [
                { name: "userEmail", value: userAuth.email },
                { name: "page", value: currentPage - 1 },
                { name: "size", value: messagesPerPage },
            ]);

            const responseJson = await ApiCall(
                action,
                ENDPOINTS.getMessagesByUserEmail.method,
                userAuth.token
            );

            setMessages(responseJson._embedded.messages);
            setTotalPages(responseJson.page.totalPages);

            setIsLoadingMessages(false);
        };
        fetchUserMessages().catch((error: any) => {
            setIsLoadingMessages(false);
            setHttpError(error.messages);
        });

        window.scrollTo(0, 0);
    }, [currentPage, messagesPerPage, userAuth.email, userAuth.token]);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    if (isLoadingMessages) {
        return <SpinnerLoading />;
    }

    if (httpError) {
        return (
            <div className="container m-5">
                <p>{httpError}</p>
            </div>
        );
    }

    return (
        <div className="mt-2">
            {messages.length > 0 ? (
                <>
                    <h5>{(t('messages.currentQ/A'))} </h5>
                    {messages.map((message) => (
                        <div key={message.id}>
                            <Card className="mt-2 shadow p-3 bg-body rounded">
                                <Card.Title>
                                    {(t('messages.case#'))}{message.id}: {message.title}
                                </Card.Title>
                                <h6>{message.userEmail}</h6>
                                <Card.Text>{message.question}</Card.Text>
                                <hr />
                                <div>
                                    <Card.Title>{(t('messages.response'))} </Card.Title>
                                    {message.response && message.adminEmail ? (
                                        <>
                                            <h6>{message.adminEmail} {(t('messages.admin'))} </h6>
                                            <p>{message.response}</p>
                                        </>
                                    ) : (
                                        <p>
                                            <i>
                                                {(t('messages.pendingResponseFromAdministration'))}
                                            </i>
                                        </p>
                                    )}
                                </div>
                            </Card>
                        </div>
                    ))}
                </>
            ) : (
                <h5>{(t('messages.allQuestionsYouSubmitWillBeShownHere'))} </h5>
            )}
            {totalPages > 1 && (
                <TLPagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                />
            )}
        </div>
    );
};

import { useState } from "react";
import { Badge } from "react-bootstrap";

export const Wallet: React.FC<{
    money: number;
    formattedMoney: string;
}> = (props) => {

    const [hovered, setHovered] = useState(false);

    return (
        <h4 style={{ marginRight: "65px" }} >
            <Badge className="mb-3" bg="success" style={{ float: "right", marginRight: "50px" }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                {hovered ?
                    props.money.toLocaleString("en-US", { minimumFractionDigits: 0 })
                    :
                    props.formattedMoney}
            </Badge>
        </h4>
    );
};

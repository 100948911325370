import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { Wallet } from "../Components/Wallet";
import TeamModel from "../Models/TeamModel";
import Countdown from "react-countdown";
import { MessageModal } from "../Components/MessageModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TLError } from "../Utility/TLError";

export const Lawyer = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));
    const [successMessage, setSuccessMessage] = useState("");

    const [isPlayerBought, setIsPlayerBought] = useState(false);

    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    const [clientPlayer, setClientPlayer] = useState<number | undefined>(team?.lawyer.clientPlayer.id);

    useEffect(() => {
        document.title = t('title.lawyer');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    async function startHearing() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.startHearing.action,
                ENDPOINTS.startHearing.method,
                userAuth.token,
                {
                    playerId: clientPlayer,
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function endHearing() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.endHearing.action,
                ENDPOINTS.endHearing.method,
                userAuth.token,
                {
                    playerId: team?.lawyer.clientPlayer.id
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setIsLoading(false);
            setError(new TLError("", ""));
            setSuccessMessage(team?.lawyer.clientPlayer.name + " " + team?.lawyer.clientPlayer.surname + " is successfully defended!");
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            setSuccessMessage("");
        }
    };

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            <MessageModal title={t('common.success')} message={successMessage} isShown={successMessage !== ""} close={() => setSuccessMessage("")} />
            {
                team &&
                    team.players.filter(p => p.status === "R").length > 0 ?
                    <Card className="mb-3">
                        <Card.Header>{t('lawyer.title')}</Card.Header>
                        <Card.Body>
                            <Form id="form" noValidate>
                                <Row className="justify-content-md-center text-center mb-3">
                                    <Col md={5} mb={5} >
                                        <Form.Label>{team.lawyer.clientPlayer && team.lawyer.clientPlayerEndDate ? <Countdown date={moment(team.lawyer.clientPlayerEndDate).toDate()} /> : t('lawyer.defendAPlayer')}</Form.Label>
                                        <Form.Select
                                            disabled={team.lawyer.clientPlayerEndDate ? true : false}
                                            value={team.lawyer.clientPlayer.id}
                                            onChange={(e) => setClientPlayer(parseInt(e.target.value))}>
                                            <option key={"0"} value="0">{t('common.succes')}</option>
                                            {team?.players.filter(p => p.status && p.status === "R").map(p =>
                                                <option key={p.id} value={p.id}>{p.name + " " + p.surname + t('lawyer.penalizedFor') + p.remainingStatusDay + t('lawyer.day')}</option>
                                            )}
                                        </Form.Select>
                                        <Button
                                            variant="primary"
                                            className="mt-3"
                                            onClick={() => {
                                                team.lawyer.clientPlayer && team.lawyer.clientPlayerEndDate ? endHearing() : startHearing();
                                            }}                                >
                                            {team.lawyer.clientPlayer && team.lawyer.clientPlayerEndDate ? t('lawyer.completeHearing') : t('lawyer.defend')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    : t('lawyer.youDontHaveAnyPenalizedPlayers')
            }
        </Container >

    );
};

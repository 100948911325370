import { useState } from "react";
import { Button, Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { LoadingModal } from "./LoadingModal";
import { SpinnerLoading } from "./SpinnerLoading";
import TeamModel from "../Models/TeamModel";
import { ApiCall } from "../Utility/ApiCall";
import { FormationPlayer } from "./FormationPlayer";
import { Lineup } from "./Lineup";
import { useTranslation } from "react-i18next";

export const Tactics: React.FC<{
    team: TeamModel;
}> = (props) => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [displayWarning, setDisplayWarning] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formationId, setFormationId] = useState(props.team.tactic.formationId);
    const [gameplayTypeId, setGameplayTypeId] = useState(props.team.tactic.gameplayTypeId);
    const [hasOffsideTactic, setHasOffsideTactic] = useState(props.team.tactic.hasOffsideTactic);
    const [forwardLineTacticId, setForwardLineTacticId] = useState(props.team.tactic.forwardLineTacticId);
    const [midfielderLineTacticId, setMidfielderLineTacticId] = useState(props.team.tactic.midfielderLineTacticId);
    const [defenderLineTacticId, setDefenderLineTacticId] = useState(props.team.tactic.defenderLineTacticId);
    const [markingId, setMarkingId] = useState(props.team.tactic.markingId);
    const [tacklingId, setTacklingId] = useState(props.team.tactic.tacklingId);
    const [pressing, setPressing] = useState(props.team.tactic.pressing);
    const [style, setStyle] = useState(props.team.tactic.style);
    const [tempo, setTempo] = useState(props.team.tactic.tempo);
    const [player1, setPlayer1] = useState<number | undefined>(props.team.tactic.player1 && props.team.tactic.player1.id);
    const [player2, setPlayer2] = useState<number | undefined>(props.team.tactic.player2 && props.team.tactic.player2.id);
    const [player3, setPlayer3] = useState<number | undefined>(props.team.tactic.player3 && props.team.tactic.player3.id);
    const [player4, setPlayer4] = useState<number | undefined>(props.team.tactic.player4 && props.team.tactic.player4.id);
    const [player5, setPlayer5] = useState<number | undefined>(props.team.tactic.player5 && props.team.tactic.player5.id);
    const [player6, setPlayer6] = useState<number | undefined>(props.team.tactic.player6 && props.team.tactic.player6.id);
    const [player7, setPlayer7] = useState<number | undefined>(props.team.tactic.player7 && props.team.tactic.player7.id);
    const [player8, setPlayer8] = useState<number | undefined>(props.team.tactic.player8 && props.team.tactic.player8.id);
    const [player9, setPlayer9] = useState<number | undefined>(props.team.tactic.player9 && props.team.tactic.player9.id);
    const [player10, setPlayer10] = useState<number | undefined>(props.team.tactic.player10 && props.team.tactic.player10.id);
    const [player11, setPlayer11] = useState<number | undefined>(props.team.tactic.player11 && props.team.tactic.player11.id);
    const [substitute1, setSubstitute1] = useState<number | undefined>(props.team.tactic.substitute1 && props.team.tactic.substitute1.id);
    const [substitute2, setSubstitute2] = useState<number | undefined>(props.team.tactic.substitute2 && props.team.tactic.substitute2.id);
    const [substitute3, setSubstitute3] = useState<number | undefined>(props.team.tactic.substitute3 && props.team.tactic.substitute3.id);
    const [substitute4, setSubstitute4] = useState<number | undefined>(props.team.tactic.substitute4 && props.team.tactic.substitute4.id);
    const [substitute5, setSubstitute5] = useState<number | undefined>(props.team.tactic.substitute5 && props.team.tactic.substitute5.id);
    const [substitute6, setSubstitute6] = useState<number | undefined>(props.team.tactic.substitute6 && props.team.tactic.substitute6.id);
    const [substitute7, setSubstitute7] = useState<number | undefined>(props.team.tactic.substitute7 && props.team.tactic.substitute7.id);

    const [captain, setCaptain] = useState<number | undefined>(props.team.tactic.captain && props.team.tactic.captain.id);
    const [freekickTaker, setFreekickTaker] = useState<number | undefined>(props.team.tactic.freekickTaker && props.team.tactic.freekickTaker.id);
    const [penaltyTaker, setPenaltyTaker] = useState<number | undefined>(props.team.tactic.penaltyTaker && props.team.tactic.penaltyTaker.id);
    const [cornerTaker, setCornerTaker] = useState<number | undefined>(props.team.tactic.cornerTaker && props.team.tactic.cornerTaker.id);

    async function updateTactics() {

        setDisplayWarning("");
        setIsLoading(true);
        try {
            await ApiCall(
                ENDPOINTS.updateTactics.action,
                ENDPOINTS.updateTactics.method,
                userAuth.token,
                {
                    teamId: props.team.id,
                    formationId,
                    gameplayTypeId,
                    hasOffsideTactic,
                    forwardLineTacticId,
                    midfielderLineTacticId,
                    defenderLineTacticId,
                    markingId,
                    tacklingId,
                    pressing,
                    style,
                    tempo,
                    player1,
                    player2,
                    player3,
                    player4,
                    player5,
                    player6,
                    player7,
                    player8,
                    player9,
                    player10,
                    player11,
                    substitute1,
                    substitute2,
                    substitute3,
                    substitute4,
                    substitute5,
                    substitute6,
                    substitute7,
                    captain,
                    penaltyTaker,
                    freekickTaker,
                    cornerTaker
                }
            );

            setIsLoading(false);
            setDisplaySuccess(true);
            setDisplayWarning("");
        } catch (error: any) {
            setIsLoading(false);
            setDisplaySuccess(false);
            setDisplayWarning(error.message);
        }
    };

    function validatePlayerId(value: string) {
        if (player1 === parseInt(value)) {
            setPlayer1(0);
        }
        if (player2 === parseInt(value)) {
            setPlayer2(0);
        }
        if (player3 === parseInt(value)) {
            setPlayer3(0);
        }
        if (player4 === parseInt(value)) {
            setPlayer4(0);
        }
        if (player5 === parseInt(value)) {
            setPlayer5(0);
        }
        if (player6 === parseInt(value)) {
            setPlayer6(0);
        }
        if (player7 === parseInt(value)) {
            setPlayer7(0);
        }
        if (player8 === parseInt(value)) {
            setPlayer8(0);
        }
        if (player9 === parseInt(value)) {
            setPlayer9(0);
        }
        if (player10 === parseInt(value)) {
            setPlayer10(0);
        }
        if (player11 === parseInt(value)) {
            setPlayer11(0);
        }
        if (substitute1 === parseInt(value)) {
            setSubstitute1(0);
        }
        if (substitute2 === parseInt(value)) {
            setSubstitute2(0);
        }
        if (substitute3 === parseInt(value)) {
            setSubstitute3(0);
        }
        if (substitute4 === parseInt(value)) {
            setSubstitute4(0);
        }
        if (substitute5 === parseInt(value)) {
            setSubstitute5(0);
        }
        if (substitute6 === parseInt(value)) {
            setSubstitute6(0);
        }
        if (substitute7 === parseInt(value)) {
            setSubstitute7(0);
        }
    }

    return (
        <Container className="mt-2 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {(t('tactics.successfullyUpdatedTheTactics'))}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Row className="justify-content-md-center text-center mb-3">
                <Col md={3} mb={3}>
                    <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => updateTactics()}
                    >
                        {(t('tactics.update'))}
                    </Button>
                </Col>
            </Row>
            <Card className="mb-3">
                <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
                <Card.Header>{(t('tactics.lineup'))}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.formation'))}</Form.Label>
                                <Form.Select
                                    value={formationId}
                                    onChange={(e) => setFormationId(parseInt(e.target.value))}>
                                    <option key={""}>{(t('tactics.formation'))}</option>
                                    <option key={"0"} value="0">4-3-3</option>
                                    <option key={"1"} value="1">4-5-1</option>
                                    <option key={"2"} value="2">4-2-3-1</option>
                                    <option key={"3"} value="3">4-4-2</option>
                                    <option key={"4"} value="4">3-2-5</option>
                                    <option key={"5"} value="5">3-2-3-2</option>
                                    <option key={"6"} value="6">3-3-4</option>
                                    <option key={"7"} value="7">3-4-3</option>
                                    <option key={"8"} value="8">3-3-2-2</option>
                                    <option key={"9"} value="9">3-5-2</option>
                                    <option key={"10"} value="10">4-2-4</option>
                                    <option key={"11"} value="11">5-2-3</option>
                                    <option key={"12"} value="12">5-3-2</option>
                                    <option key={"13"} value="13">5-3-1-1</option>
                                    <option key={"14"} value="14">5-4-1</option>
                                    <option key={"15"} value="15">6-3-1</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Lineup
                            player11={player11}
                            player10={player10}
                            player9={player9}
                            player8={player8}
                            player7={player7}
                            player6={player6}
                            player5={player5}
                            player4={player4}
                            player3={player3}
                            player2={player2}
                            player1={player1}
                            validatePlayerId={validatePlayerId}
                            setPlayer11={setPlayer11}
                            setPlayer10={setPlayer10}
                            setPlayer9={setPlayer9}
                            setPlayer8={setPlayer8}
                            setPlayer7={setPlayer7}
                            setPlayer6={setPlayer6}
                            setPlayer5={setPlayer5}
                            setPlayer4={setPlayer4}
                            setPlayer3={setPlayer3}
                            setPlayer2={setPlayer2}
                            setPlayer1={setPlayer1}
                            team={props.team}
                            formationId={formationId}
                        />
                    </Form>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                {isLoading && <SpinnerLoading />}
                <Card.Header>Bench</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <FormationPlayer playerId={substitute1} playerLabel={(t('tactics.substitute1'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute1} team={props.team} />
                            <FormationPlayer playerId={substitute2} playerLabel={(t('tactics.substitute2'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute2} team={props.team} />
                            <FormationPlayer playerId={substitute3} playerLabel={(t('tactics.substitute3'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute3} team={props.team} />
                            <FormationPlayer playerId={substitute4} playerLabel={(t('tactics.substitute4'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute4} team={props.team} />
                        </Row>
                        <Row className="justify-content-md-center text-center mb-3">
                            <FormationPlayer playerId={substitute5} playerLabel={(t('tactics.substitute5'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute5} team={props.team} />
                            <FormationPlayer playerId={substitute6} playerLabel={(t('tactics.substitute6'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute6} team={props.team} />
                            <FormationPlayer playerId={substitute7} playerLabel={(t('tactics.substitute7'))} validatePlayerId={validatePlayerId} setPlayer={setSubstitute7} team={props.team} />
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>Tactics</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.gameplayType'))}</Form.Label>
                                <Form.Select
                                    value={gameplayTypeId}
                                    onChange={(e) =>
                                        setGameplayTypeId(parseInt(e.target.value))
                                    }>
                                    <option key={""} disabled>{(t('tactics.gameplayType'))}</option>
                                    <option key={"0"} value="0">{(t('tactics.longBall'))}</option>
                                    <option key={"1"} value="1">{(t('tactics.passingGame'))}</option>
                                    <option key={"2"} value="2">{(t('tactics.wingPlay'))}</option>
                                    <option key={"3"} value="3">{(t('tactics.counterAttack'))}</option>
                                    <option key={"4"} value="4">{(t('tactics.shootOnSight'))}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.offsideTactics'))}</Form.Label>
                                <Form.Select
                                    value={hasOffsideTactic}
                                    onChange={(e) => {
                                        setHasOffsideTactic(parseInt(e.target.value));
                                    }}>
                                    <option key={""}>{(t('tactics.offsideTactics'))}</option>
                                    <option key={"0"} value="0">{(t('common.no'))}</option>
                                    <option key={"1"} value="1">{(t('common.yes'))}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.forwardLineTactic'))}</Form.Label>
                                <Form.Select
                                    value={forwardLineTacticId}
                                    onChange={(e) => {
                                        setForwardLineTacticId(parseInt(e.target.value));
                                    }}>
                                    <option key={""} disabled>{(t('tactics.forwardLineTactic'))}</option>
                                    <option key={"0"} value="0">{(t('tactics.attackOnly'))}</option>
                                    <option key={"1"} value="1">{(t('tactics.helpMiddle'))}</option>
                                    <option key={"2"} value="2">{(t('tactics.dropDownToDefense'))}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.midfielderLineTactic'))}</Form.Label>
                                <Form.Select
                                    value={midfielderLineTacticId}
                                    onChange={(e) => {
                                        setMidfielderLineTacticId(parseInt(e.target.value));
                                    }}>
                                    <option key={""} disabled>{(t('tactics.midfielderLineTactic'))}</option>
                                    <option key={"0"} value="0">{(t('tactics.pushForward'))}</option>
                                    <option key={"1"} value="1">{(t('tactics.stayInPosition'))}</option>
                                    <option key={"2"} value="2">{(t('tactics.helpDefense'))}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.defenseLineTactic'))} </Form.Label>
                                <Form.Select
                                    value={defenderLineTacticId}
                                    onChange={(e) => {
                                        setDefenderLineTacticId(parseInt(e.target.value));
                                    }}>
                                    <option key={""} disabled>{(t('tactics.defenseLineTactic'))} </option>
                                    <option key={"0"} value="0">{(t('tactics.pushMiddle'))} </option>
                                    <option key={"1"} value="1">{(t('tactics.stayInPosition'))}</option>
                                    <option key={"2"} value="2">{(t('tactics.defendDeep'))} </option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.marking'))}</Form.Label>
                                <Form.Select
                                    value={markingId}
                                    onChange={(e) => {
                                        setMarkingId(parseInt(e.target.value));
                                    }}>
                                    <option key={""}>{(t('tactics.marking'))}</option>
                                    <option key={"0"} value="0">{(t('tactics.zonalMarking'))}</option>
                                    <option key={"1"} value="1">{(t('tactics.manMarking'))}</option>
                                </Form.Select>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.tackling'))}</Form.Label>
                                <Form.Select
                                    value={tacklingId}
                                    onChange={(e) => {
                                        setTacklingId(parseInt(e.target.value));
                                    }}>
                                    <option key={""}>{(t('tactics.tackling'))}</option>
                                    <option key={"0"} value="0">{(t('tactics.gentle'))}</option>
                                    <option key={"1"} value="1">{(t('tactics.casual'))}</option>
                                    <option key={"2"} value="2">{(t('tactics.normal'))}</option>
                                    <option key={"3"} value="3">{(t('tactics.aggressive'))}</option>
                                    <option key={"4"} value="4">{(t('tactics.hardcore'))}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3} className="align-center">
                                <Form.Label>{(t('tactics.press'))} {pressing}</Form.Label>
                                <Form.Range
                                    value={pressing}
                                    onChange={(e) => {
                                        setPressing(parseInt(e.target.value));
                                    }} />
                            </Col>
                            <Col md={3} mb={3} className="align-center">
                                <Form.Label>{(t('tactics.tempo'))} {tempo}</Form.Label>
                                <Form.Range
                                    value={tempo}
                                    onChange={(e) => {
                                        setTempo(parseInt(e.target.value));
                                    }} />
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.style'))} {style}</Form.Label>
                                <Form.Range
                                    value={style}
                                    onChange={(e) => {
                                        setStyle(parseInt(e.target.value));
                                    }} />
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
            <Card>
                <Card.Header>{(t('tactics.specialists'))}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.captain'))}</Form.Label>
                                <Form.Select
                                    value={captain}
                                    onChange={(e) => setCaptain(parseInt(e.target.value))}>
                                    <option key={"0"} value="0">{(t('tactics.captain'))}</option>
                                    {props.team.players.map((p) => {
                                        return (
                                            <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Text>
                                    {(t('tactics.captainInfo'))}
                                </Form.Text>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.cornerTaker'))}</Form.Label>
                                <Form.Select
                                    value={cornerTaker}
                                    onChange={(e) => setCornerTaker(parseInt(e.target.value))}>
                                    <option key={"0"} value="0">{(t('tactics.cornerTaker'))}</option>
                                    {props.team.players.map((p) => {
                                        return (
                                            <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Text>
                                    {(t('tactics.cornerTakerInfo'))}
                                </Form.Text>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center mb-3">
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.freekickTaker'))}</Form.Label>
                                <Form.Select
                                    value={freekickTaker}
                                    onChange={(e) => setFreekickTaker(parseInt(e.target.value))}>
                                    <option key={"0"} value="0">{(t('tactics.freekickTaker'))}</option>
                                    {props.team.players.map((p) => {
                                        return (
                                            <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Text>
                                    {(t('tactics.freekickTakerInfo'))}
                                </Form.Text>
                            </Col>
                            <Col md={3} mb={3} >
                                <Form.Label>{(t('tactics.penaltyTaker'))}</Form.Label>
                                <Form.Select
                                    value={penaltyTaker}
                                    onChange={(e) => setPenaltyTaker(parseInt(e.target.value))}>
                                    <option key={"0"} value="0">{(t('tactics.penaltyTaker'))}</option>
                                    {props.team.players.map((p) => {
                                        return (
                                            <option key={p.id} disabled={p.status !== "O" && p.status !== "I" && p.status !== "R"} value={p.id} > {p.name + " " + p.surname + "  -  ATK: " + p.attack + " | DEF: " + p.defense}</option>
                                        );
                                    })}
                                </Form.Select>
                                <Form.Text>
                                    {(t('tactics.penaltyTakerInfo'))}
                                </Form.Text>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

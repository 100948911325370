import { Button, ListGroup, Modal, ProgressBar } from "react-bootstrap";
import PlayerModel from "../Models/PlayerModel";
import { LabelDataPair } from "./LabelDataPair";
import { useTranslation } from "react-i18next";

export const PlayerDetailModal: React.FC<{
    showModal: boolean;
    setShowModal: any;
    chosenPlayer: PlayerModel | null;
}> = (props) => {

    const { t } = useTranslation();

    return (
        props.chosenPlayer &&
        <Modal show={props.showModal} onHide={() => { props.setShowModal(false); }}>
            <Modal.Header closeButton>
                <Modal.Title>{props.chosenPlayer.name + " " + props.chosenPlayer.surname}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    {t('playerDetailModal.training')}
                    <ProgressBar animated now={50} />
                </div>
                <ListGroup>
                    <LabelDataPair label={t('playerDetailModal.#')} value={props.chosenPlayer.jerseyNumber} />
                    <LabelDataPair label={t('playerDetailModal.age')} value={props.chosenPlayer.age} />
                    <LabelDataPair label={t('playerDetailModal.birthDate')} value={props.chosenPlayer.birthDate} />
                    <LabelDataPair label={t('playerDetailModal.nationality')} value={props.chosenPlayer.nationality} />
                    <LabelDataPair label={t('playerDetailModal.position')} value={props.chosenPlayer.position} />
                    <LabelDataPair label={t('playerDetailModal.fit')} value={props.chosenPlayer.fit} />
                    <LabelDataPair label={t('playerDetailModal.morale')} value={props.chosenPlayer.morale} />
                    <LabelDataPair label={t('playerDetailModal.goals')} value={props.chosenPlayer.goal} />
                    <LabelDataPair label={t('playerDetailModal.assists')} value={props.chosenPlayer.assist} />
                    <LabelDataPair label={t('playerDetailModal.value')} value={props.chosenPlayer.formattedValue} />
                    <LabelDataPair label={t('playerDetailModal.status')} value={props.chosenPlayer.status} />
                    <LabelDataPair label={t('playerDetailModal.played')} value={props.chosenPlayer.playedMatches} />
                    <LabelDataPair label={t('playerDetailModal.rating')} value={props.chosenPlayer.overallRating} />
                    <LabelDataPair label={t('playerDetailModal.redCards')} value={props.chosenPlayer.redCards} />
                    <LabelDataPair label={t('playerDetailModal.yellowCards')} value={props.chosenPlayer.yellowCards} />
                    <LabelDataPair label={t('playerDetailModal.played')} value={props.chosenPlayer.playedMatches} />
                    <LabelDataPair label={t('playerDetailModal.attack')} value={props.chosenPlayer.attack} />
                    <LabelDataPair label={t('playerDetailModal.defense')} value={props.chosenPlayer.defense} />
                    <LabelDataPair label={t('playerDetailModal.totalPenaltiesTaken')} value={props.chosenPlayer.totalPenalties} />
                    <LabelDataPair label={t('playerDetailModal.successfulPenalties')} value={props.chosenPlayer.successfulPenalties} />
                    <LabelDataPair label={t('playerDetailModal.totalFreekicksTaken')} value={props.chosenPlayer.totalFreekicks} />
                    <LabelDataPair label={t('playerDetailModal.successfulFreekicks')} value={props.chosenPlayer.successfulFreekicks} />
                    <LabelDataPair label={t('playerDetailModal.corners')} value={props.chosenPlayer.corners} />
                    <LabelDataPair label={t('playerDetailModal.offsides')} value={props.chosenPlayer.offsides} />
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.setShowModal(false); }}>
                    {t('common.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

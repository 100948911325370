import { useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import MessageModel from "../Models/MessageModel";
import { useTranslation } from "react-i18next";

export const PostNewMessage = () => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [title, setTitle] = useState("");
    const [question, setQuestion] = useState("");
    const [displayWarning, setDisplayWarning] = useState(false);
    const [displaySuccess, setDisplaySuccess] = useState(false);

    async function submitNewQuestion() {
        if (userAuth?.isAuthenticated && title !== "" && question !== "") {
            const messageRequestModel: MessageModel = new MessageModel(
                title,
                question
            );

            await ApiCall(
                ENDPOINTS.submitNewQuestion.action,
                ENDPOINTS.submitNewQuestion.method,
                userAuth?.token,
                messageRequestModel
            );

            setTitle("");
            setQuestion("");
            setDisplayWarning(false);
            setDisplaySuccess(true);
        } else {
            setDisplayWarning(true);
            setDisplaySuccess(false);
        }
    }

    return (
        <Card className="mt-3">
            <Card.Header> {(t('postNewMessage.askQuestionToTorndelAdmin'))}</Card.Header>
            <Card.Body>
                <Form>
                    {displayWarning && (
                        <Alert key={"danger"} variant={"danger"}>
                            {(t('postNewMessage.allFieldsMustBeFilledOut'))}
                        </Alert>
                    )}
                    {displaySuccess && (
                        <Alert key={"success"} variant={"success"}>
                            {(t('postNewMessage.questionAddedSuccessfully'))}
                        </Alert>
                    )}
                    <div className="mb-3">
                        <Form.Label>{(t('postNewMessage.title'))}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={(t('postNewMessage.title'))}
                            id="exampleFormControlInput1"
                            onChange={(e) => setTitle(e.target.value)}
                            value={title}
                        />
                    </div>
                    <div className="mb-3">
                        <Form.Label>{(t('postNewMessage.question'))}</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder={(t('postNewMessage.question'))}
                            id="exampleFormControlTextarea1"
                            rows={3}
                            onChange={(e) => setQuestion(e.target.value)}
                            value={question}
                        />
                    </div>
                    <div>
                        <Button
                            variant="primary"
                            className="mb-3"
                            onClick={submitNewQuestion}
                        >
                            {(t('postNewMessage.submitQuestion'))}
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
};

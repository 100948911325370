import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { MatchLine } from "../Components/MatchLine";
import { TLPagination } from "../Components/TLPagination";
import LeagueModel from "../Models/LeagueModel";
import { Wallet } from "../Components/Wallet";
import TeamModel from "../Models/TeamModel";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const Fixture = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [league, setLeague] = useState<LeagueModel>();
    const [selectedDay, setSelectedDay] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    const paginate = (pageNumber: number) => setSelectedDay(pageNumber);

    useEffect(() => {
        document.title = t('title.fixture');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);

    useEffect(() => {

        const getLeagueFixture = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getLeagueFixture.action,
                ENDPOINTS.getLeagueFixture.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );

            setLeague(responseJson.leagueDTO);
            setTotalPages(Math.max(...responseJson.leagueDTO.fixtures.map((f: { day: number; }) => f.day)));
            setSelectedDay(getSelectedDay(responseJson));
            setError(new TLError("", ""));
        };
        getLeagueFixture().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);

    function getSelectedDay(responseJson: any) {
        let currentDay = 1;
        if (Math.max(...responseJson.leagueDTO.fixtures.map((f: { day: number; }) => f.day)) > responseJson.leagueDTO.currentDay) {
            if (responseJson.leagueDTO.currentDay !== 0) {
                currentDay = responseJson.leagueDTO.currentDay;
            }
        } else {
            currentDay = Math.max(...responseJson.leagueDTO.fixtures.map((f: { day: number; }) => f.day));
        }
        return currentDay;
    }

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <h4>{t('fixture.title')}</h4>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} >
                <TLPagination
                    currentPage={selectedDay}
                    totalPages={totalPages}
                    paginate={paginate}
                />
            </div>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {selectedDay}
            </div>
            {
                league?.fixtures.filter(f => f.day === selectedDay).map(f => <MatchLine key={f.id} match={f.match} />)
            }
        </Container>
    );
};

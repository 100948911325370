import { Button, Modal } from "react-bootstrap";
import MatchModel from "../Models/MatchModel";
import { useTranslation } from "react-i18next";

export const MatchModal: React.FC<{
    setShowMatchModal: any;
    match: MatchModel | null;
    showMatchModal: boolean;
}> = (props) => {

    const { t } = useTranslation();

    return (
        props.match &&
        <Modal size="lg" show={props.showMatchModal} onHide={() => { props.setShowMatchModal(false); }}>
            <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">{props.match.homeTeam.name + " " + props.match.homeTeamScore + " : " + props.match.awayTeamScore + " " + props.match.awayTeam.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className="text-center">
                    {props.match.homeTeamScore + (t('matchModal.goals')) + props.match.awayTeamScore}<br />
                    {props.match.homeTeamShots + (t('matchModal.shots')) + props.match.awayTeamShots}<br />
                    {props.match.homeTeamCorners + (t('matchModal.corners')) + props.match.awayTeamCorners}<br />
                    {props.match.homeTeamOffsides + (t('matchModal.offsides')) + props.match.awayTeamOffsides}<br />
                    {props.match.homeTeamFouls + (t('matchModal.fouls')) + props.match.awayTeamFouls}<br />
                    {props.match.homeTeamInjuries + (t('matchModal.injuries')) + props.match.awayTeamInjuries}<br />
                    {props.match.homeTeamYellowCards + (t('matchModal.yellowCards')) + props.match.awayTeamYellowCards}<br />
                    {props.match.homeTeamRedCards + (t('matchModal.redCards')) + props.match.awayTeamRedCards}<br />
                    {props.match.homeTeamFreekicks + (t('matchModal.freekicks')) + props.match.awayTeamFreekicks}<br />
                    {props.match.homeTeamPenalties + (t('matchModal.penalties')) + props.match.awayTeamPenalties}<br /></div>
            </Modal.Body>
            <hr />
            <Modal.Body>
                {props.match.events.map(e => {
                    return (
                        <div key={e.id}>
                            <div style={{ float: e.player.team.id === props.match?.homeTeam.id ? "left" : "right" }}>
                                {e.minute + "' " + (t('matchModal.' + e.typeId)) + " - " + e.player.name + " " + e.player.surname + (e.secondPlayer.name !== undefined ? " & " + e.secondPlayer.name + " " + e.secondPlayer.surname : "")}
                            </div>
                            <br />
                        </div>);
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => { props.setShowMatchModal(false); }}>
                    {(t('common.close'))}
                </Button>
            </Modal.Footer>
        </Modal>

    );
};

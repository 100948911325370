import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const MessageModal: React.FC<{
    title: string;
    message: string;
    isShown: boolean;
    close: any;
}> = (props) => {

    const { t } = useTranslation();

    return (
        <Modal show={props.isShown} onHide={props.close}>
            <Modal.Header className="px-4" closeButton>
                <Modal.Title className="ms-auto">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className="text-center">
                    {props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.close}>
                    {(t('common.close'))}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

import { useEffect, useState } from "react";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { MyTeamLeagueStatus } from "../Components/MyTeamLeagueStatus";
import TeamModel from "../Models/TeamModel";
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const MyTeams = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [teams, setTeams] = useState<TeamModel[]>([]);

    useEffect(() => {
        document.title = t('title.myTeams');
    }, [t]);

    useEffect(() => {

        const getUsersTeams = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getUsersTeams.action,
                ENDPOINTS.getUsersTeams.method,
                userAuth.token,
                {}
            );

            setTeams(responseJson.teams);
            setError(new TLError("", ""));
            setIsLoading(false);
        };
        getUsersTeams().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token]);

    async function setCurrentTeam(teamId: number) {
        setIsLoading(true);

        try {

            await ApiCall(
                ENDPOINTS.validateCurrentTeam.action,
                ENDPOINTS.validateCurrentTeam.method,
                userAuth.token,
                { teamId: teamId }
            );

            secureLocalStorage.setItem("userContext", JSON.stringify({ currentTeamId: teamId }));
            setIsLoading(false);
            setError(new TLError("", ""));
            window.location.reload();
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    }
    console.log(teams);

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            {teams && teams.map((team, i) => {
                return (
                    <div key={team.id}>
                        <Card className="mt-3 shadow p-3 mb-3 bg-body rounded">
                            <Row className="g-0">
                                <Col sm={3} md={2}>
                                    <div className="d-none d-lg-block">
                                        <img
                                            src={team.id % 2 === 0 ? require("./../Assets/images/football1.jpg") : require("./../Assets/images/football2.jpg")}
                                            width="123"
                                            height="196"
                                            alt="Default"
                                        />
                                    </div>
                                    <div className="d-lg-none d-flex justify-content-center align-items-center">
                                        <img
                                            src={require("./../Assets/images/football1.jpg")}
                                            width="123"
                                            height="196"
                                            alt="Default"
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <MyTeamLeagueStatus team={team} />
                                    {userContext.currentTeamId !== team.id ?
                                        <Button
                                            variant="primary"
                                            className="mt-3"
                                            onClick={() => setCurrentTeam(team.id)}
                                        >
                                            {t('myTeams.setCurrentTeam')}
                                        </Button>
                                        : <Button
                                            variant="success"
                                            className="mt-3"
                                            disabled
                                        >
                                            {t('myTeams.currentlySelectedTeam')}
                                        </Button>}
                                    <Link
                                        type="button"
                                        className="btn btn-primary mt-3 ms-3 text-white"
                                        to={"myTeams/" + team.id}
                                    >
                                        {t('myTeams.details')}
                                    </Link>
                                </Col>
                                <Col md={2}>
                                    <Card.Body>
                                        <h4>{team.name}</h4>
                                        <Card.Title> {t('myTeams.totalTeams')} {team.league.teamCount} </Card.Title>
                                        <hr />
                                        <Card.Text>
                                            {t('myTeams.teamSignedUpAt')} {team.signDate}
                                        </Card.Text>
                                    </Card.Body>

                                </Col>
                            </Row>
                        </Card>
                        <hr />
                    </div>
                );
            })}
            <h3 className="mt-3"> {t('myTeams.lookingForMore')} </h3>
            <Link className="btn btn-primary" to={"leagues"}>
                {t('myTeams.manageAnotherTeam')}
            </Link>
        </Container >
    );
};

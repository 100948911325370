import { Table } from "react-bootstrap";
import TeamModel from "../Models/TeamModel";
import { useTranslation } from "react-i18next";

export const MyTeamLeagueStatus: React.FC<{
    team: TeamModel;
}> = (props) => {

    const { t } = useTranslation();

    return (
        <div key={props.team.id}>
            {(t('leagueTable.league'))} {props.team.league.name}
            {props.team &&
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>{t('leagueTable.#')}</th>
                            <th>{t('leagueTable.name')}</th>
                            <th>{t('leagueTable.matchesPlayed')}</th>
                            <th>{t('leagueTable.win')}</th>
                            <th>{t('leagueTable.draw')}</th>
                            <th>{t('leagueTable.lose')}</th>
                            <th>{t('leagueTable.goalForward')}</th>
                            <th>{t('leagueTable.goalAgainst')}</th>
                            <th>{t('leagueTable.goalDifference')}</th>
                            <th>{t('leagueTable.points')}</th>
                            <th>{t('leagueTable.teamValue')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={props.team.id}>
                            <td>{0}</td>
                            <td>{props.team.name} <img style={{ float: "right" }} src={require("./../Assets/Flags/" + props.team.code + ".jpg")} width="30" height="20" alt="Default" /></td>
                            <td>{props.team.win + props.team.draw + props.team.loss}</td>
                            <td>{props.team.win}</td>
                            <td>{props.team.draw}</td>
                            <td>{props.team.loss}</td>
                            <td>{props.team.goalsFor}</td>
                            <td>{props.team.goalsAgainst}</td>
                            <td>{props.team.goalsFor - props.team.goalsAgainst}</td>
                            <td>{props.team.point}</td>
                            <td>{props.team.teamValue.toLocaleString(undefined, { minimumFractionDigits: 2 })}</td>
                        </tr>
                    </tbody>
                </Table>
            }
        </div>
    );
};

import secureLocalStorage from "react-secure-storage";

function useUserContext() {

    const storageUserContext = secureLocalStorage.getItem("userContext");

    let userContext = { currentTeamId: -1 };

    if (storageUserContext) {
        userContext = JSON.parse(storageUserContext.toString());
    }

    return userContext;

};

export { useUserContext };

import { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Messages } from "../Components/Messages";
import { PostNewMessage } from "../Components/PostNewMessage";
import { useTranslation } from "react-i18next";

export const MessagesPage = () => {

    const [messagesClick, setMessagesClick] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('title.messages');
    }, [t]);

    return (
        <Container className="mt-3 mb-2">
            <Tabs
                id="nav-tab"
                role="tablist"
                onSelect={(k) => setMessagesClick(k === "messages")}
            >
                <Tab eventKey="submitNewQuestion" title={t('messagesPage.submitQuestion')}>
                    <PostNewMessage />
                </Tab>
                <Tab eventKey="messages" title={t('messagesPage.Q/AResponsePending')}>
                    {messagesClick ? <Messages /> : <></>}
                </Tab>
            </Tabs>
        </Container>
    );
};

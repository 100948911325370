import { useEffect, useState } from "react";
import { Container, Card, Row, Col, Tabs, Tab } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { SquadTable } from "../Components/SquadTable";
import { Tactics } from "../Components/Tactics";
import { Training } from "../Components/Training";
import TeamModel from "../Models/TeamModel";
import { Stadium } from "../Components/Stadium";
import { Wallet } from "../Components/Wallet";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const Team = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const teamId = window.location.pathname.split("/")[2];

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [team, setTeam] = useState<TeamModel>();

    useEffect(() => {
        document.title = team?.name ? team.name + t('title.torndel') : t('title.team');
    }, [t, team?.name]);

    useEffect(() => {

        const getLeagues = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: teamId }
            );

            setTeam(responseJson.team);
            setError(new TLError("", ""));
            setIsLoading(false);
        };
        getLeagues().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [teamId, userAuth.token]);

    async function putPlayerOnTransferList(playerId: number, transferPrice: number) {

        setIsLoading(true);

        try {
            const responseJson = await ApiCall(
                ENDPOINTS.putPlayerOnTransferList.action,
                ENDPOINTS.putPlayerOnTransferList.method,
                userAuth.token,
                {
                    playerId: playerId,
                    transferPrice: transferPrice
                }
            );

            setTeam(responseJson.team);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function removePlayerFromTransferList(playerId: number) {

        setIsLoading(true);

        try {
            const responseJson = await ApiCall(
                ENDPOINTS.removePlayerFromTransferList.action,
                ENDPOINTS.removePlayerFromTransferList.method,
                userAuth.token,
                {
                    playerId: playerId
                }
            );

            setTeam(responseJson.team);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            {team &&
                <div key={team.id}>
                    <h5>{(t('team.team'))} {team.name}</h5>
                    <br />
                    <Card className="shadow p-3 mb-3 bg-body rounded">
                        <Row className="g-0">
                            <Col md={2}>
                                <div className="d-none d-lg-block">
                                    <img src={require("./../Assets/Flags/" + team.code + ".jpg")} style={{ float: "right" }} width="180" height="120" alt="Default" />
                                </div>
                                <div className="d-lg-none d-flex justify-content-center align-items-center">
                                    <img src={require("./../Assets/Flags/" + team.code + ".jpg")} style={{ float: "right" }} width="180" height="120" alt="Default" />
                                </div>
                            </Col>
                            <Col>
                                <Card.Body>
                                    <Card.Title> {(t('team.totalPlayers'))} {team.players.length} </Card.Title>
                                    <h4>{team.name}</h4>
                                    <hr />
                                    <Card.Text>
                                        {" "}
                                        {(t('team.totalTeamValue'))} {team.formattedTeamValue}
                                    </Card.Text>
                                    <Card.Text>
                                        {" "}
                                    </Card.Text>
                                </Card.Body>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Tabs defaultActiveKey="squad">
                                    <Tab eventKey="squad" title="Squad">
                                        <SquadTable players={team.players} putPlayerOnTransferList={putPlayerOnTransferList} removePlayerFromTransferList={removePlayerFromTransferList} />
                                    </Tab>
                                    {userContext.currentTeamId.toString() === teamId &&
                                        <Tab eventKey="tactics" title={(t('team.tactics'))}>
                                            <Tactics team={team} />
                                        </Tab>
                                    }
                                    {userContext.currentTeamId.toString() === teamId &&
                                        <Tab eventKey="training" title={(t('team.training'))}>
                                            <Training team={team} />
                                        </Tab>
                                    }
                                    {userContext.currentTeamId.toString() === teamId &&
                                        <Tab eventKey="stadium" title={(t('team.stadium'))}>
                                            <Stadium team={team} />
                                        </Tab>
                                    }
                                </Tabs>
                            </Col>
                        </Row>
                    </Card>
                    <hr />
                </div>
            }
        </Container>
    );
};

import jwt_decode from "jwt-decode";
import secureLocalStorage from "react-secure-storage";

function useAuth() {

    const token = localStorage.getItem("token");

    if (token) {
        try {

            let decoded = jwt_decode(token);

            let decodedObject = Object(decoded);

            decodedObject.isAuthenticated = Date.now() / 1000 <= decodedObject.exp;
            decodedObject.token = token;

            return decodedObject;
        } catch (error: any) {
            localStorage.removeItem("token");
            secureLocalStorage.removeItem("userContext");
            window.location.replace(process.env.REACT_APP_SITE_URL || '');
        }
    } else {
        return false;
    }
};

export { useAuth };

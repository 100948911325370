import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { MatchLine } from "../Components/MatchLine";
import MatchModel from "../Models/MatchModel";
import TeamModel from "../Models/TeamModel";
import { Wallet } from "../Components/Wallet";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const Matches = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    const [matches, setMatches] = useState<MatchModel[]>([]);
    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    useEffect(() => {
        document.title = t('title.matches');
    }, [t]);

    useEffect(() => {

        const getMatches = async () => {

            setIsLoading(true);

            const responseJson = await ApiCall(
                ENDPOINTS.getTeamMatches.action,
                ENDPOINTS.getTeamMatches.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );

            setMatches(responseJson.matches);
            setError(new TLError("", ""));
            setIsLoading(false);
        };
        getMatches().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId]);

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            {matches && matches.map(m => {
                return (
                    <MatchLine key={m.id} match={m} />
                );
            })}
        </Container >
    );
};

import { Link, NavLink } from "react-router-dom";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import secureLocalStorage from "react-secure-storage";
import { useTranslation } from "react-i18next";

export const Header = () => {

    const userAuth = useAuth();
    const { t, i18n } = useTranslation();

    const handleLogout = () => {
        localStorage.removeItem("token");
        secureLocalStorage.removeItem("userContext");
        window.location.replace(process.env.REACT_APP_SITE_URL + "/login");
    };

    const userContext = useUserContext();

    console.log(i18n);
    console.log(userAuth);
    console.log(userContext);

    function getTitleUrl(): string {
        let url = "";
        if (userAuth.isAuthenticated && userContext && userContext.currentTeamId !== -1) {
            url = "/home";
        }
        else if (userAuth.isAuthenticated) {
            url = "/myTeams";
        }
        else {
            url = "/login";
        }
        return url;
    }

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className="navbar navbar-expand-lg navbar-dark main-color py-3"
            variant="dark"
        >
            <Container fluid>
                <Navbar.Brand href={getTitleUrl()}>Torndel</Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="navbarNavDropdown"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle Navigation"
                ></Navbar.Toggle>
                <Navbar.Collapse id="navbarNavDropdown">
                    <Nav className="me-auto">
                        {userAuth.isAuthenticated && userContext && userContext.currentTeamId !== -1 && (
                            <NavLink className="nav-link" to="/home">
                                {t('header.home')}
                            </NavLink>
                        )}
                        {userAuth.isAuthenticated && (
                            <NavLink className="nav-link" to="/myTeams">
                                {t('header.myTeams')}
                            </NavLink>
                        )}
                        {userAuth.isAuthenticated && userContext && userContext.currentTeamId !== -1 && (
                            <NavDropdown title={t('header.results')} id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/matches">
                                    {t('header.matches')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/leagueTable">
                                    {t('header.leagueTable')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/fixture">
                                    {t('header.fixture')}
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        {userAuth.isAuthenticated && userContext && userContext.currentTeamId !== -1 && (
                            <NavDropdown title={t('header.staff')} id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/doctor">
                                    {t('header.doctor')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/lawyer">
                                    {t('header.lawyer')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/scout">
                                    {t('header.scout')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/dataAnalyst">
                                    {t('header.dataAnalyst')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/transferList">
                                    {t('header.transfer')}
                                </NavDropdown.Item>
                            </NavDropdown>
                        )}
                        <NavLink className="nav-link" to="/leagues">
                            {t('header.leagues')}
                        </NavLink>
                    </Nav>
                    <Nav >
                        <NavDropdown title={t('header.language')} id="basic-nav-dropdown">
                            <NavDropdown.Item style={{ backgroundColor: i18n.language === "tr" ? "#89afc0" : "" }} onClick={() => { changeLanguage("tr"); }}>
                                {t('language.turkish')}
                            </NavDropdown.Item>
                            <NavDropdown.Item style={{ backgroundColor: i18n.language === "en" ? "#89afc0" : "" }} onClick={() => { changeLanguage("en"); }}>
                                {t('language.english')}
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    {userAuth.isAuthenticated && (
                        <Nav >
                            <NavDropdown title={userAuth.username} id="basic-nav-dropdown" style={{ marginRight: '100px' }}>
                                <NavDropdown.Item as={Link} to="/messages">
                                    {t('header.messages')}
                                </NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/settings">
                                    {t('header.settings')}
                                </NavDropdown.Item>
                                {userAuth.roles.filter((r: { value: string; }) => r.value === "admin").length > 0 &&
                                    <NavDropdown.Item as={Link} to="/admin">
                                        {t('header.admin')}
                                    </NavDropdown.Item>
                                }
                                <NavDropdown.Item onClick={() => { handleLogout(); }}>
                                    {t('header.logout')}
                                </NavDropdown.Item>

                            </NavDropdown>
                        </Nav>
                    )}
                    <ul className="navbar-nav ">
                        {!userAuth.isAuthenticated && (
                            <div>
                                <Link type="button" style={{ marginRight: "10px" }} className="btn btn-outline-light" to="/login">
                                    {t('header.signIn')}
                                </Link>
                                <Link type="button" className="btn btn-outline-light" to="/register">
                                    {t('header.register')}
                                </Link>
                            </div>
                        )}
                    </ul>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
};

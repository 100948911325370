import { useState, useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { useUserContext } from "../Utility/useUserContext";
import { ErrorModal } from "../Components/ErrorModal";
import { LoadingModal } from "../Components/LoadingModal";
import { Wallet } from "../Components/Wallet";
import TeamModel from "../Models/TeamModel";
import Countdown from "react-countdown";
import { MessageModal } from "../Components/MessageModal";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { TLError } from "../Utility/TLError";

export const Doctor = () => {

    const userAuth = useAuth();
    const userContext = useUserContext();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));
    const [successMessage, setSuccessMessage] = useState("");

    const [isPlayerBought, setIsPlayerBought] = useState(false);

    const [team, setTeam] = useState<TeamModel | undefined>(undefined);

    const [treatedPlayer, setTreatedPlayer] = useState<number | undefined>(team?.doctor.treatedPlayer.id);

    useEffect(() => {
        document.title = t('title.doctor');
    }, [t]);

    useEffect(() => {

        const getTeam = async () => {

            const responseJson = await ApiCall(
                ENDPOINTS.getTeam.action,
                ENDPOINTS.getTeam.method,
                userAuth.token,
                { teamId: userContext.currentTeamId }
            );
            setTeam(responseJson.team);
            setError(new TLError("", ""));
        };
        getTeam().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });

    }, [userAuth.token, userContext.currentTeamId, isPlayerBought]);

    async function startTreatment() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.startTreatment.action,
                ENDPOINTS.startTreatment.method,
                userAuth.token,
                {
                    playerId: treatedPlayer,
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setError(new TLError("", ""));
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
        }
    };

    async function endTreatment() {

        setIsLoading(true);

        try {
            await ApiCall(
                ENDPOINTS.endTreatment.action,
                ENDPOINTS.endTreatment.method,
                userAuth.token,
                {
                    playerId: team?.doctor.treatedPlayer.id
                }
            );

            setIsPlayerBought(!isPlayerBought);

            setIsLoading(false);
            setError(new TLError("", ""));
            setSuccessMessage(team?.doctor.treatedPlayer.name + " " + team?.doctor.treatedPlayer.surname + t('doctor.isSuccessfullyResearched'));
        } catch (error: any) {
            setIsLoading(false);
            setError(error);
            setSuccessMessage("");
        }
    };

    return (
        <Container className="mt-3 mb-3">
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Wallet money={team?.money || 0} formattedMoney={team?.formattedMoney || "0"} />
            <br /><br />
            <MessageModal title={t('common.success')} message={successMessage} isShown={successMessage !== ""} close={() => setSuccessMessage("")} />
            {
                team &&
                    team.players.filter(p => p.status === "I").length > 0 ?
                    <Card className="mb-3">
                        <Card.Header>{t('doctor.title')}</Card.Header>
                        <Card.Body>
                            <Form id="form" noValidate>
                                <Row className="justify-content-md-center text-center mb-3">
                                    <Col md={5} mb={5} >
                                        <Form.Label>{team.doctor.treatedPlayer && team.doctor.treatedPlayerEndDate ? <Countdown date={moment(team.doctor.treatedPlayerEndDate).toDate()} /> : "Heal a player!"}</Form.Label>
                                        <Form.Select
                                            disabled={team.doctor.treatedPlayerEndDate ? true : false}
                                            value={team.doctor.treatedPlayer.id}
                                            onChange={(e) => setTreatedPlayer(parseInt(e.target.value))}>
                                            <option key={"0"} value="0">{t('doctor.chooseAPlayerToHeal')}</option>
                                            {team?.players.filter(p => p.status && p.status === "I").map(p =>
                                                <option key={p.id} value={p.id}>{p.name + " " + p.surname + t('doctor.injuredFor') + p.remainingStatusDay + t('doctor.day')}</option>
                                            )}
                                        </Form.Select>
                                        <Button
                                            variant="primary"
                                            className="mt-3"
                                            onClick={() => {
                                                team.doctor.treatedPlayer && team.doctor.treatedPlayerEndDate ? endTreatment() : startTreatment();
                                            }}                                >
                                            {team.doctor.treatedPlayer && team.doctor.treatedPlayerEndDate ? t('doctor.completeTreatment') : t('doctor.heal')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                    :
                    t('doctor.youDontHaveAnyInjuredPlayers')
            }
        </Container >

    );
};

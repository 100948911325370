import { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { ErrorModal } from "./ErrorModal";
import { LoadingModal } from "./LoadingModal";
import { MatchModal } from "./MatchModal";
import MatchModel from "../Models/MatchModel";
import { RefereeCol } from "./RefereeCol";
import { useTranslation } from "react-i18next";
import { TLError } from "../Utility/TLError";

export const MatchLine: React.FC<{
    match: MatchModel;
}> = (props) => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [chosenMatch, setChosenMatch] = useState<MatchModel | null>(null);
    const [showMatchModal, setShowMatchModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<TLError>(new TLError("", ""));

    function displayMatchModal(m: MatchModel): void {

        const getMatch = async () => {

            setIsLoading(true);

            const responseJson = await ApiCall(
                ENDPOINTS.getMatch.action,
                ENDPOINTS.getMatch.method,
                userAuth.token,
                { matchId: props.match.id }
            );

            setError(new TLError("", ""));
            setIsLoading(false);
            setChosenMatch(responseJson.match);
            setShowMatchModal(true);
        };
        getMatch().catch((error: any) => {
            setIsLoading(false);
            setError(error);
        });
    }

    console.log(props.match);

    function getMatchColor(type: string) {
        let color = "";
        if (type) {
            if (type === "L") {
                color = "lightcyan";
            } else if (type === "F") {
                color = "honeydew";
            }
        }
        return color;
    }

    return (
        <Container>
            <LoadingModal isLoading={isLoading} setIsLoading={() => setIsLoading(false)} />
            <ErrorModal error={error} isShown={error?.code !== ""} close={() => setError(new TLError("", ""))} />
            <Card onClick={() => { props.match.played && (displayMatchModal(props.match)); }} className="mt-3 shadow p-3 mb-3 " style={{ cursor: "pointer", backgroundColor: getMatchColor(props.match.type) }}>
                <Row>
                    <Col style={{ textAlign: "left" }}>
                        {props.match.date} <br />{t('match.' + props.match.type)}
                    </Col>
                    <Col>
                        <img style={{ float: "right" }} src={require("./../Assets/Flags/" + props.match.homeTeam.code + ".jpg")} width="30" height="20" alt="Default" />
                    </Col>
                    <Col style={{ textAlign: "center" }}>
                        {props.match.homeTeam.name + " " + (props.match.played ? props.match.homeTeamScore : "") + " : " + (props.match.played ? props.match.awayTeamScore : "") + " " + props.match.awayTeam.name}
                    </Col>
                    <Col>
                        <img style={{ float: "left" }} src={require("./../Assets/Flags/" + props.match.awayTeam.code + ".jpg")} width="30" height="20" alt="Default" />
                    </Col>
                    <RefereeCol referee={props.match.referee} />
                </Row>
            </Card>
            <MatchModal match={chosenMatch} showMatchModal={showMatchModal} setShowMatchModal={setShowMatchModal} />
        </Container>

    );
};

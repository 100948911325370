import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const SpinnerLoading = () => {

    const { t } = useTranslation();

    return (
        <Container
            className="m-5 d-flex justify-content-center"
            style={{
                height: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">
                    {(t('spinnerLoading.loading'))}
                </span>
            </div>
        </Container>
    );
};

import { useEffect, useState } from "react";
import { Container, Alert, Card, Form, Row, Col, Button } from "react-bootstrap";

import { ApiCall } from "../Utility/ApiCall";
import { ENDPOINTS } from "../Utility/Endpoints";
import { useAuth } from "../Utility/useAuth";
import { SpinnerLoading } from "../Components/SpinnerLoading";
import { useTranslation } from "react-i18next";

const Settings = () => {

    const userAuth = useAuth();
    const { t } = useTranslation();

    const [displayWarning, setDisplayWarning] = useState("");
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [formValidated, setFormValidated] = useState(false);

    const [name, setName] = useState("");
    const [nameValidation, setNameValidation] = useState<boolean | undefined>(undefined);
    const [nameValidationMessage, setNameValidationMessage] = useState("");

    const [surname, setSurname] = useState("");
    const [surnameValidation, setSurnameValidation] = useState<boolean | undefined>(undefined);
    const [surnameValidationMessage, setSurnameValidationMessage] = useState("");

    const [username, setUsername] = useState("");

    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [email, setEmail] = useState("");
    const [emailValidation, setEmailValidation] = useState<boolean | undefined>(undefined);
    const [emailValidationMessage, setEmailValidationMessage] = useState("");

    const [password, setPassword] = useState("");
    const [passwordValidation, setPasswordValidation] = useState<boolean | undefined>(undefined);
    const [passwordValidationMessage, setPasswordValidationMessage] = useState("");

    const [createDate, setCreateDate] = useState("");

    const nameOnChange = (value: string) => {
        setName(value);
        setFormValidated(false);
        if (value) {
            setNameValidation(true);
            setNameValidationMessage(t('settings.successfulValidation'));
        } else {
            setNameValidation(false);
            setNameValidationMessage(t('settings.nameFailedValidation'));
        }
    };

    const surnameOnChange = (value: string) => {
        setSurname(value);
        setFormValidated(false);
        if (value) {
            setSurnameValidation(true);
            setSurnameValidationMessage(t('settings.successfulValidation'));
        } else {
            setSurnameValidation(false);
            setSurnameValidationMessage(t('settings.surnameFailedValidation'));
        }
    };

    const emailOnChange = (value: string) => {
        setEmail(value);
        setFormValidated(false);
        if (value && EMAIL_REGEX.test(value)) {
            setEmailValidation(true);
            setEmailValidationMessage(t('settings.successfulValidation'));
        } else {
            setEmailValidation(false);
            setEmailValidationMessage(t('settings.emailFailedValidation'));
        }
    };

    const passwordOnChange = (value: string) => {
        setPassword(value);
        setFormValidated(false);
        if (value && value.length >= 8) {
            setPasswordValidation(true);
            setPasswordValidationMessage(t('settings.successfulValidation'));
        } else {
            setPasswordValidation(false);
            setPasswordValidationMessage(t('settings.passwordFailedValidation'));
        }
    };

    useEffect(() => {
        document.title = t('title.settings');
    }, [t]);

    useEffect(() => {

        const getUser = async () => {

            const response = await ApiCall(
                ENDPOINTS.getUser.action,
                ENDPOINTS.getUser.method,
                userAuth.token,
                {

                }
            );

            setUsername(response.username);
            setCreateDate(response.createDate);

            nameOnChange(response.name);
            surnameOnChange(response.surname);
            emailOnChange(response.email);

            setIsLoading(false);
        };
        getUser().catch((error: any) => {
            setIsLoading(false);
            setDisplayWarning(error.message);
        });
    }, [userAuth.token]);

    async function updateUser() {
        nameOnChange(name);
        surnameOnChange(surname);
        emailOnChange(email);
        passwordOnChange(password);

        if (!nameValidation || !surnameValidation || !emailValidation || !passwordValidation) {
            setDisplayWarning(t('settings.missingFieldsValidation'));
        } else {
            setDisplayWarning("");
            setIsLoading(true);

            const crypto = require("crypto-js");
            const hashedPassword = crypto.MD5(password).toString();

            try {
                const responseJson = await ApiCall(
                    ENDPOINTS.updateUser.action,
                    ENDPOINTS.updateUser.method,
                    userAuth.token,
                    {
                        name: name,
                        surname: surname,
                        username: username,
                        email: email,
                        password: hashedPassword
                    }
                );

                localStorage.setItem("token", responseJson.token);
                setIsLoading(false);
                setDisplaySuccess(true);
                setDisplayWarning("");
                window.location.reload();
            } catch (error: any) {
                setIsLoading(false);
                setDisplaySuccess(false);
                setDisplayWarning(error.message);
            }
        }
    };

    return (
        <Container className="mt-5 mb-5">
            {displaySuccess && (
                <Alert key={"success"} variant={"success"}>
                    {(t('settings.successfullyUpdatedTheSettings'))}
                </Alert>
            )}
            {displayWarning && (
                <Alert key={"danger"} variant={"danger"}>
                    {displayWarning}
                </Alert>
            )}
            <Card>
                {isLoading && <SpinnerLoading />}
                <Card.Header>{(t('settings.title'))}</Card.Header>
                <Card.Body>
                    <Form id="form" noValidate validated={formValidated}>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3} >
                                <Form.Group as={Col} controlId="validationCustom01">
                                    <Form.Label>{(t('settings.name'))}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        placeholder={(t('settings.name'))}
                                        value={name}
                                        required
                                        onChange={(e) => nameOnChange(e.target.value)}
                                        isInvalid={nameValidation !== undefined ? !nameValidation : undefined}
                                        isValid={nameValidation !== undefined ? nameValidation : undefined}
                                    />
                                    <Form.Control.Feedback type={nameValidation ? "valid" : "invalid"}>{nameValidationMessage}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{(t('settings.surname'))}</Form.Label>
                                <Form.Control
                                    id="surname"
                                    type="text"
                                    name="surname"
                                    placeholder={(t('settings.surname'))}
                                    value={surname}
                                    onChange={(e) => surnameOnChange(e.target.value)}
                                    isInvalid={surnameValidation !== undefined ? !surnameValidation : undefined}
                                    isValid={surnameValidation !== undefined ? surnameValidation : undefined}
                                />
                                <Form.Control.Feedback type={surnameValidation ? "valid" : "invalid"}>{surnameValidationMessage}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <br />
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{(t('settings.username'))}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="username"
                                    placeholder={(t('settings.username'))}
                                    value={username}
                                    disabled
                                />
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{(t('settings.email'))}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder={(t('settings.email'))}
                                    value={email}
                                    onChange={(e) => emailOnChange(e.target.value)}
                                    isInvalid={emailValidation !== undefined ? !emailValidation : undefined}
                                    isValid={emailValidation !== undefined ? emailValidation : undefined}
                                />
                                <Form.Control.Feedback type={emailValidation ? "valid" : "invalid"}>{emailValidationMessage}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        <br />
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Form.Label>{(t('settings.password'))}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder={(t('settings.password'))}
                                    value={password}
                                    onChange={(e) => passwordOnChange(e.target.value)}
                                    isInvalid={passwordValidation !== undefined ? !passwordValidation : undefined}
                                    isValid={passwordValidation !== undefined ? passwordValidation : undefined}
                                />
                                <Form.Control.Feedback type={passwordValidation ? "valid" : "invalid"}>{passwordValidationMessage}</Form.Control.Feedback>
                                <Form.Text id="passwordHelpBlock" muted>
                                    {(t('settings.inOrderToPerformAnUpdateYouMustEnterYourPassword'))}
                                </Form.Text>
                            </Col>
                            <Col md={3} mb={3}>
                                <Form.Label>{(t('settings.registrationDate'))}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="createDate"
                                    value={createDate}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center text-center">
                            <Col md={3} mb={3}>
                                <Button
                                    variant="primary"
                                    className="mt-3"
                                    onClick={() => updateUser()}
                                >

                                    {(t('settings.update'))}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Settings;

import { Col, Container, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const Footer = () => {

    const { t } = useTranslation();


    return (
        <div className="main-color">
            <Container>
                <footer className="d-flex flex-wrap justify-content-between align-items-center py-5">
                    <Col md={4} className="col-md-4 mb-0 text-white">
                        © Torndel, Inc
                    </Col>
                    <Nav className="col-md-4 justify-content-end">
                        <Nav.Item>
                            <Link to="/home" className="nav-link px-2 text-white">
                                {t('header.home')}
                            </Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Link to="/leagues" className="nav-link px-2 text-white">
                                {t('header.leagues')}
                            </Link>
                        </Nav.Item>
                    </Nav>
                </footer>
            </Container>
        </div>
    );
};

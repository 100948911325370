import { Modal } from "react-bootstrap";
import { SpinnerLoading } from "./SpinnerLoading";
import { useTranslation } from "react-i18next";

export const LoadingModal: React.FC<{ isLoading: any; setIsLoading: any; }> = (props) => {

    const { t } = useTranslation();

    return (
        <Modal show={props.isLoading} onHide={props.setIsLoading}>
            <Modal.Header className="px-4" >
                <Modal.Title>{(t('loadingModal.loading'))}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <SpinnerLoading />
            </Modal.Body>
        </Modal >
    );
};
